import React, { useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import StyledTooltip from "../../../shared/Components/Styled/StyledTooltip";
import DownloadIcon from "@mui/icons-material/Download";
import Papa from "papaparse";
import StyledIconButton from "../../../shared/Components/Styled/StyledIconButton";
import { ProductWarranty } from "../../../common/types/ProductWarrantyTypes";

interface DownloadReportButtonProps {
  displayedWarranties: ProductWarranty[];
  allWarranties: ProductWarranty[];
}

const DownloadReportButton: React.FC<DownloadReportButtonProps> = ({
  displayedWarranties,
  allWarranties
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadReport = (warranties: ProductWarranty[]) => {
    const csvData = warranties.map((warranty) => ({
      "Serial No.": warranty.productSiteInformation.serialNumber,
      "Distributor Email": warranty.distributorInformation.email,
      Company: warranty.productSiteInformation.company,
      "Device Model": warranty.productSiteInformation.deviceModel,
      "Warranty Type": warranty.faultDescription.warrantyType,
      "Credit Status": warranty.faultDescription.creditStatus,
      "Credit Amount": warranty.faultDescription.creditAmount,
      "Replacement Unit": warranty.faultDescription.replacementUnit,
      "Return Reason": warranty.faultDescription.returnReason,
      Status: warranty.status,
      "Submitted Date": new Date(warranty.createdAt).toLocaleDateString()
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `ProductWarranties_Report_${new Date().toLocaleDateString()}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleCloseMenu(); // Close the dropdown after selection
  };

  return (
    <>
      <StyledTooltip title="Download Report">
        <Box sx={{ lineHeight: 0 }}>
          <StyledIconButton variantType="transparent" onClick={handleOpenMenu}>
            <DownloadIcon />
          </StyledIconButton>
        </Box>
      </StyledTooltip>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleDownloadReport(displayedWarranties)}>
          Download Selection
        </MenuItem>
        <MenuItem onClick={() => handleDownloadReport(allWarranties)}>
          Download All
        </MenuItem>
      </Menu>
    </>
  );
};

export default DownloadReportButton;
