import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Dismiss24Regular } from "@fluentui/react-icons";
import StepSerial from "../../Shared/DeviceInstallation/Steps/StepSerial";
import StepName from "../../Shared/DeviceInstallation/Steps/StepName";
import StepCustomer from "../../Shared/DeviceInstallation/Steps/StepCustomer";
import StepAddCustomer from "../../Shared/DeviceInstallation/Steps/StepAddCustomer";
import StepCartridge from "../../Shared/DeviceInstallation/Steps/StepCartridge";
import StepLocation from "../../Shared/DeviceInstallation/Steps/StepLocation";
import StepDeviceCreated from "../../Shared/DeviceInstallation/Steps/StepDeviceCreated";
import StepNavigation from "../../Shared/DeviceInstallation/StepNavigation";
import type {
  InstallationStep,
  InstallationData
} from "../../../common/types/InstallDeviceTypes";
import { createICleanDevice } from "../../../services/device.service";
import useAuthStore from "../../../store/authStore";
import useDeviceStore from "../../../store/useDeviceStore";

interface AddDeviceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const INITIAL_DATA: InstallationData = {
  serialNumber: "",
  deviceName: "",
  customerEmail: "",
  cartridgeType: "",
  location: {
    country: "",
    city: "",
    details: ""
  }
};

const AddDeviceModal: React.FC<AddDeviceModalProps> = ({ isOpen, onClose }) => {
  const { user } = useAuthStore();
  const { allDevices, fetchDevices, setSelectedIcleanDevices } =
    useDeviceStore();
  const [step, setStep] = useState<InstallationStep>("serial");
  const [data, setData] = useState<InstallationData>(INITIAL_DATA);
  const [isLoading, setIsLoading] = useState(false);
  const [deviceCreated, setDeviceCreated] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateData = (fields: Partial<InstallationData>) => {
    setData((prev) => ({ ...prev, ...fields }));
  };

  const goBack = () => {
    switch (step) {
      case "name":
        setStep("serial");
        break;
      case "customer":
        setStep("name");
        break;
      case "add-customer":
        setStep("customer");
        break;
      case "cartridge":
        setStep("customer");
        break;
      case "location":
        setStep("cartridge");
        break;
      default:
        break;
    }
  };

  const handleContinue = async () => {
    if (step === "location") {
      setStep("device-created");
      await handleCreateDevice();
    } else if (step === "device-created") {
      onClose();
      setStep("serial");
      setData(INITIAL_DATA);
    } else {
      nextStep();
    }
  };

  const handleCreateDevice = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const devicePayload = {
        deviceInformation: {
          deviceId: data.serialNumber,
          serial: data.serialNumber,
          deviceName: data.deviceName,
          customerEmail: data.customerEmail,
          installerEmail: user?.email.toLowerCase(),
          installationCartridgeType: data.cartridgeType
        },
        deviceLocation: {
          latitude: 0,
          longitude: 0,
          country: data.location.country,
          state: "",
          city: data.location.city,
          address: "",
          postalCode: "",
          room: "",
          door: "",
          block: "",
          building: "",
          additionalDetails: data.location.details
        }
      };

      const response = await createICleanDevice(devicePayload);

      const updatedDevices = [response.device, ...allDevices];
      setSelectedIcleanDevices(updatedDevices);
      fetchDevices();

      setDeviceCreated(true);
      return response.device.deviceInformation.deviceId;
    } catch (err: any) {
      console.error("Failed to create device:", err);
      setError(err || "Failed to create device");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    switch (step) {
      case "serial":
        setStep("name");
        break;
      case "name":
        setStep("customer");
        break;
      case "customer":
        setStep("cartridge");
        break;
      case "cartridge":
        setStep("location");
        break;
      default:
        break;
    }
  };

  const canContinue = () => {
    switch (step) {
      case "serial":
        return data.serialNumber !== "";
      case "name":
        return data.deviceName !== "";
      case "customer":
        return data.customerEmail !== "";
      case "cartridge":
        return data.cartridgeType !== "";
      case "location":
        return data.location.country !== "" && data.location.city !== "";
      case "device-created":
        return true;
      default:
        return false;
    }
  };

  const renderStep = () => {
    switch (step) {
      case "serial":
        return <StepSerial data={data} updateData={updateData} />;
      case "name":
        return <StepName data={data} updateData={updateData} />;
      case "customer":
        return (
          <StepCustomer
            data={data}
            updateData={updateData}
            onAddNewCustomer={() => setStep("add-customer")}
          />
        );
      case "add-customer":
        return (
          <StepAddCustomer
            data={data}
            updateData={updateData}
            onSuccess={() => setStep("cartridge")}
          />
        );
      case "cartridge":
        return <StepCartridge data={data} updateData={updateData} />;
      case "location":
        return <StepLocation data={data} updateData={updateData} />;
      case "device-created":
        return (
          <StepDeviceCreated
            isLoading={isLoading}
            error={error}
            text="Click continue to finish and close this dialog"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.85)"
          }
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
        >
          <Dismiss24Regular />
        </IconButton>
      </DialogTitle>
      <DialogContent>{renderStep()}</DialogContent>
      <StepNavigation
        step={step}
        isLoading={isLoading}
        onBack={goBack}
        onContinue={handleContinue}
        canContinue={canContinue()}
        isDeviceCreated={deviceCreated}
        error={error}
      />
    </Dialog>
  );
};

export default AddDeviceModal;
