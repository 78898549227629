import React from "react";
import {
  Box,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from "@mui/material";
import { InstallationData } from "../../../../common/types/InstallDeviceTypes";

interface StepCartridgeProps {
  data: InstallationData;
  updateData: (fields: Partial<InstallationData>) => void;
}

const StepCartridge: React.FC<StepCartridgeProps> = ({ data, updateData }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold"
          }}
        >
          Cartridge Type
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary"
          }}
        >
          Select the type of cartridge you are installing
        </Typography>
      </Box>
      <FormControl fullWidth margin="normal" required>
        <InputLabel id="cartridge-type-label">Cartridge Type</InputLabel>
        <Select
          labelId="cartridge-type-label"
          id="cartridge-type-select"
          value={data.cartridgeType}
          label="Cartridge Type"
          onChange={(e) => updateData({ cartridgeType: e.target.value })}
        >
          <MenuItem value="SAO-24">SAO-24</MenuItem>
          <MenuItem value="SAO-4">SAO-4</MenuItem>
          <MenuItem value="AO/Desiccant">AO/Desiccant Only</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default StepCartridge;
