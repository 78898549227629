import { useState } from "react";
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  IconButton
} from "@mui/material";
import StyledTooltip from "../../../shared/Components/Styled/StyledTooltip";
import {
  ContentCopy as CopyIcon,
  Check as CheckIcon
} from "@mui/icons-material";

type CodeBlockParams = {
  text: string;
  code: string;
};

const CodeBlock = ({ text, code }: CodeBlockParams) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code).then(
        () => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        },
        () => {
          console.error("Failed to copy text");
        }
      );
    } else {
      // Fallback for older browsers (IE)
      const textArea = document.createElement("textarea");
      textArea.value = code;
      document.body.appendChild(textArea);

      textArea.select();
      try {
        document.execCommand("copy");
        setCopied(true);

        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error("Unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        position: "relative",
        p: 2,
        mb: 2,
        backgroundColor: "#f5f5f5",
        overflowX: "auto"
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {text}
        </Typography>
        <StyledTooltip
          opacity={1}
          title={copied ? "Copied!" : "Copy to clipboard"}
        >
          <IconButton aria-label="copy" onClick={handleCopy}>
            {copied ? <CheckIcon color="success" /> : <CopyIcon />}
          </IconButton>
        </StyledTooltip>
      </Box>
      <Box
        sx={{
          backgroundColor: "#2d3748",
          color: "#ffffff",
          borderRadius: "4px",
          padding: "16px",
          fontFamily: "monospace"
        }}
      >
        <Typography variant="body2" component="pre" sx={{ margin: 0 }}>
          {code}
        </Typography>
      </Box>
    </Paper>
  );
};

const CustomerIntegrationTab = () => {
  const endpointRequest = `GET /api/v1/customer-integration/usage/all?apiKey={yourApiKey}`;
  const exampleRequest = `GET /api/v1/customer-integration/usage/all?apiKey=YOUR_API_KEY_HERE`;
  const exampleResponse = `{
  "message": "Device usage fetched successfully",

  "devices": {
    "device_1": [
      {"usage": 12.3, "unit": "liters", "timestamp": "2024-01-01T12:00:00Z"},

      {"usage": 15.7, "unit": "liters", "timestamp": "2024-01-02T12:00:00Z"}
    ],
    "device_2": [
      {"usage": 10.5, "unit": "liters", "timestamp": "2024-01-01T13:00:00Z"}
    ]
  }
}`;
  const errorResponse = `{
  "error": "Invalid API key format"
}`;

  return (
    <Container sx={{ padding: "16px 0px", "& > *": { padding: "4px 0" } }}>
      <Typography variant="h4" fontWeight="bold">
        Getting Started with Our API
      </Typography>

      <Typography variant="body1" gutterBottom>
        Welcome to our API integration guide! This page will walk you through
        the steps of integrating with our system, from obtaining your API key to
        making requests for device usage data.
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Step 1: Request Access */}

      <Typography variant="h6" fontWeight="bold">
        Step 1: Request Access
      </Typography>
      <Typography variant="body1" gutterBottom>
        Before using the API, you need to request an API key. Here’s how to do
        it:
      </Typography>

      <List>
        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>1. Send an Email</strong>
                <br />
                Please send an email to <strong>dev.team@tersano.com</strong>
                with the subject line <em>"API Key Request"</em>. In the body of
                the email, include the following details:
                <ul>
                  <li>Your full name</li>
                  <li>Your company name (if applicable)</li>
                  <li>
                    Your email address (make sure it's the same one you'll use
                    for the API)
                  </li>
                </ul>
              </>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>2. Wait for a Response</strong>
                <br />
                Our team will verify your request. If approved, we will send you
                an API key, which you will use to authenticate your API
                requests.
              </>
            }
          />
        </ListItem>
      </List>

      <Divider sx={{ my: 2 }} />

      {/* Step 2: Using Your API Key */}
      <Typography variant="h6" fontWeight="bold">
        Step 2: Using Your API Key
      </Typography>
      <Typography variant="body1" gutterBottom>
        Once you've received your API key, you're ready to start making
        requests.
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        <strong>Important:</strong> Your API key is tied to your email and
        should be treated as confidential.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Here’s how to authenticate and retrieve data from our system:
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" fontWeight="bold">
        Step 3: Get Device Usage Data
      </Typography>
      <Typography variant="body1" gutterBottom>
        You can use the API key to fetch device usage data for all your devices.
        This endpoint will return detailed information on each device's usage.
      </Typography>

      {/* API Endpoint Example */}
      <CodeBlock text="Endpoint: " code={endpointRequest} />
      <CodeBlock text="Request Example" code={exampleRequest} />
      <CodeBlock text="Response Example" code={exampleResponse} />

      <Typography variant="body1" gutterBottom>
        The devices object contains usage data grouped by device ID.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Each device includes an array of usage logs, with the amount of usage
        (e.g., liters) and a timestamp.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" fontWeight="bold">
        Step 4: Error Handling
      </Typography>
      <Typography variant="body1" gutterBottom>
        If an error occurs (e.g., an invalid API key or no devices are found),
        the response will look like this:
      </Typography>

      {/* Error Response Example */}
      <CodeBlock text="Error Response Example" code={errorResponse} />

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" fontWeight="bold">
        Step 5: Best Practices
      </Typography>
      <Typography variant="body1" gutterBottom>
        Keep your API key safe and secure; never share it with unauthorized
        users.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Limit the frequency of API calls to avoid reaching rate limits.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Always validate responses and handle errors gracefully in your
        integration.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="body1" gutterBottom>
        This guide should help you get started with our API. If you run into any
        issues or have questions, feel free to contact us at
        dev.team@tersano.com.
      </Typography>
    </Container>
  );
};

export default CustomerIntegrationTab;
