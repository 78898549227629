import React from "react";
import { useTheme, Box, Typography, CircularProgress } from "@mui/material";
import {
  CheckmarkCircle48Regular,
  ErrorCircle48Regular
} from "@fluentui/react-icons";

interface StepDeviceCreatedProps {
  isLoading: boolean;
  error: any;
  text: string;
}

const StepDeviceCreated: React.FC<StepDeviceCreatedProps> = ({
  isLoading,
  error,
  text
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          py: 4
        }}
      >
        <CircularProgress size={48} />
        <Typography variant="h5" align="center">
          Creating Device...
        </Typography>
        <Typography color="text.secondary" align="center">
          Please wait while we register your device
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          py: 4
        }}
      >
        <ErrorCircle48Regular primaryFill={theme.palette.error.main} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: theme.palette.error.main }}
            align="center"
          >
            Device Creation Failed
          </Typography>
          <Typography color="text.secondary" align="center">
            {error.response.data.details}
          </Typography>
          <Typography color="text.secondary" align="center">
            Please go back and try again
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        py: 4
      }}
    >
      <CheckmarkCircle48Regular primaryFill={theme.palette.success.light} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: theme.palette.success.light }}
          align="center"
        >
          Device Created Successfully!
        </Typography>
        <Typography color="text.secondary" align="center">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default StepDeviceCreated;
