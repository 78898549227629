import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useMemo
} from "react";
import {
  Box,
  Typography,
  Chip,
  Snackbar,
  Alert,
  AlertColor,
  useTheme,
  useMediaQuery,
  TextField,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import StyledTooltip from "../../../shared/Components/Styled/StyledTooltip";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Add,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ManageAccounts
} from "@mui/icons-material";
import {
  changeInstallerStatus,
  fetchInstaller
} from "../../../services/installer.service";
import useAuthStore from "../../../store/authStore";
import useDeviceStore from "../../../store/useDeviceStore";
import AddInstallerModal from "./CreateInstallerModal";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import StyledIconButton from "../../../shared/Components/Styled/StyledIconButton";
import {
  ChangeRequestPayload,
  ChangeRequestTypes
} from "../../../common/types/ChangeRequestTypes";
import { createChangeRequest } from "../../../services/change-request.service";
import {
  AccountStatusTypes,
  UserDetails
} from "../../../common/types/UserTypes";
import EditUserModal from "../Shared/EditUserModal";
import EditUserAccountStatusModal from "../Shared/EditUserStatusModal";

const InstallersTable: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [installers, setInstallers] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false); // Add Installer Modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // Delete Installer Modal
  const [currentDeleteUser, setCurrentDeleteUser] = useState<any | null>(null);
  const [accountStatusModalOpen, setAccountStatusModalOpen] = useState(false);
  const [currentEditUser, setCurrentEditUser] = useState<UserDetails | null>(
    null
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });
  const [searchTerm, setSearchTerm] = useState("");

  const { user, userRole } = useAuthStore();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const loadInstallers = useCallback(async () => {
    try {
      if (!user) return;
      const installersData = await fetchInstaller();
      setInstallers(installersData);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to fetch installers",
        severity: "error"
      });
    }
  }, [user]);

  useEffect(() => {
    loadInstallers();
  }, [loadInstallers]);

  const handleSnackbarChange = (snackbarData: {
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }) => {
    setSnackbar(snackbarData);
  };

  const handleInstallerCreationSuccess = () => {
    setSnackbar({
      open: true,
      message: "Installer successfully added.",
      severity: "success"
    });
    loadInstallers(); // Refresh the list after adding an installer
    handleCloseModal(); // Close the modal
  };

  const handleClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      if (!user || !userRole) return;

      const { allDevices } = useDeviceStore.getState();
      const associatedDevices = allDevices.filter(
        (device) =>
          device.deviceInformation.installerEmail === currentDeleteUser?.email
      );
      if (associatedDevices.length > 0) {
        const deviceNames = associatedDevices
          .map((device) => device.deviceInformation.deviceName)
          .join(", ");
        setSnackbar({
          open: true,
          message: `Cannot delete installer: ${currentDeleteUser?.email}. It is associated with devices: ${deviceNames}. Please reassign the devices before deleting the installer.`,
          severity: "error"
        });
        return;
      }

      if (userRole !== "SuperAdmin" && userRole !== "Admin") {
        const changeRequestPayload: ChangeRequestPayload = {
          type: ChangeRequestTypes.DeleteInstaller,
          userEmail: user?.email,
          userRole: userRole,
          details: {
            userId: currentDeleteUser?._id,
            status: AccountStatusTypes.Deleted
          }
        };
        // Send the change request to the server
        await createChangeRequest(changeRequestPayload);
        setSnackbar({
          open: true,
          message: `Change Request to delete user: ${currentDeleteUser?.email} created successfully`,
          severity: "warning"
        });
      } else {
        await changeInstallerStatus(
          currentDeleteUser?._id,
          AccountStatusTypes.Deleted
        );
        // Show success message
        setSnackbar({
          open: true,
          message: `User: ${currentDeleteUser?.email} deleted successfully`,
          severity: "success"
        });
      }
      setDeleteModalOpen(false);
      setCurrentDeleteUser(null); // Clear after deletion
      loadInstallers(); // Refresh the list after deletion

      // Show success message
    } catch (error: any) {
      console.error("Error creating change request:", error.message);
      setSnackbar({
        open: true,
        message: error.message ? error.message : "Failed to delete user.",
        severity: "error"
      });
    }
  };

  const handleUpdateUser = async (updatedUser: UserDetails) => {
    try {
      if (!user || !userRole) return;

      // Get changed fields
      const changedFields = Object.keys(updatedUser).reduce((acc, key) => {
        if (
          currentEditUser &&
          updatedUser[key as keyof UserDetails] !==
            currentEditUser[key as keyof UserDetails]
        ) {
          acc[key as keyof UserDetails] = updatedUser[
            key as keyof UserDetails
          ] as any;
        }
        return acc;
      }, {} as Partial<UserDetails>);

      if (Object.keys(changedFields).length === 0) {
        setSnackbar({
          open: true,
          message: `No changes detected.`,
          severity: "info"
        });
        return; // Exit early if nothing changed
      }

      const changeRequestPayload: ChangeRequestPayload = {
        type: ChangeRequestTypes.UpdateUserDetails,
        userEmail: user?.email,
        userRole: userRole,
        details: { _id: updatedUser._id, ...changedFields } // Only send changed fields plus _id
      };

      await createChangeRequest(changeRequestPayload);

      setSnackbar({
        open: true,
        message: `User information change request created successfully`,
        severity: "success"
      });

      loadInstallers(); // Refresh the list
    } catch (error: any) {
      console.error("Error updating user:", error.message);
      setSnackbar({
        open: true,
        message: error.message || "Failed to update user.",
        severity: "error"
      });
    }
  };

  const handleUpdateAccountStatus = async (
    updatedStatus: AccountStatusTypes
  ) => {
    try {
      if (!user || !userRole) return;

      if (userRole === "SuperAdmin" || userRole === "Admin") {
        if (currentEditUser?._id) {
          await changeInstallerStatus(currentEditUser._id, updatedStatus);
        } else {
          throw new Error("User is not selected.");
        }
        setSnackbar({
          open: true,
          message: `User account status updated successfully`,
          severity: "success"
        });
      }
      loadInstallers(); // Refresh the list
      setCurrentEditUser(null); // Clear the current user

      // TODO: Implement change request for account status update if user is not an admin
    } catch (error: any) {
      console.error("Error updating account status:", error.message);
      setSnackbar({
        open: true,
        message: error.message || "Failed to update account status.",
        severity: "error"
      });
    }
  };

  const handleEditClick = (user: UserDetails) => {
    setCurrentEditUser(user);
    setEditModalOpen(true);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredInstallers = useMemo(() => {
    return installers.filter(
      (installer) =>
        installer.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        installer.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        installer.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        installer.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
        installer.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        installer.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
        installer.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        installer.state.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, installers]);

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Created Time",
      flex: 1,
      minWidth: 125,
      valueFormatter: (value) => new Date(value).toLocaleDateString()
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 130,
      valueFormatter: (value: string) =>
        value.toLowerCase() === "unknown" ? "-" : value
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 130,
      valueFormatter: (value: string) =>
        value.toLowerCase() === "unknown" ? "-" : value
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      minWidth: 130,
      valueFormatter: (value: string) =>
        value.toLowerCase() === "unknown" ? "-" : value
    },
    // {
    //   field: "state",
    //   headerName: "State",
    //   flex: 1,
    //   minWidth: 130,
    //   valueFormatter: (value: string) =>
    //     value.toLowerCase() === "unknown" ? "-" : value,
    // },
    // {
    //   field: "city",
    //   headerName: "City",
    //   flex: 1,
    //   minWidth: 130,
    //   valueFormatter: (value: string) =>
    //     value.toLowerCase() === "unknown" ? "-" : value,
    // },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   flex: 1.5,
    //   minWidth: 200,
    //   valueFormatter: (value: string) =>
    //     value.toLowerCase() === "unknown" ? "-" : value,
    // },
    // {
    //   field: "phone",
    //   headerName: "Phone",
    //   flex: 1,
    //   minWidth: 130,
    //   valueFormatter: (value: string) =>
    //     value.toLowerCase() === "unknown" ? "-" : value,
    // },
    { field: "email", headerName: "Email", flex: 1.5, minWidth: 225 },
    {
      field: "accountStatus",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value === "Active" || params.value === "Inactive"
              ? params.value
              : "Deleted"
          }
          color={
            {
              Active: "success",
              Inactive: "warning",
              Deleted: "error"
            }[params.value as "Active" | "Inactive" | "Deleted"] as
              | "success"
              | "warning"
              | "error"
          }
        />
      )
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 125,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              height: "100%",
              width: "100%"
            }}
          >
            <StyledTooltip variant="primary-dark" title="Edit">
              <Box sx={{ lineHeight: 0 }}>
                <StyledIconButton
                  variantType="transparent"
                  onClick={() => handleEditClick(row)}
                >
                  <EditIcon />
                </StyledIconButton>
              </Box>
            </StyledTooltip>
            {userRole === "SuperAdmin" ||
              (userRole === "Admin" && (
                <StyledTooltip
                  variant="primary-dark"
                  title="Edit Account Status"
                >
                  <Box sx={{ lineHeight: 0 }}>
                    <StyledIconButton
                      variantType="transparent"
                      onClick={() => {
                        setCurrentEditUser(row);
                        setAccountStatusModalOpen(true);
                      }}
                    >
                      <ManageAccounts />
                    </StyledIconButton>
                  </Box>
                </StyledTooltip>
              ))}
            <StyledTooltip variant="primary-dark" title="Delete">
              <Box sx={{ lineHeight: 0 }}>
                <StyledIconButton
                  variantType="transparent"
                  onClick={() => {
                    setCurrentDeleteUser(row);
                    setDeleteModalOpen(true);
                  }}
                >
                  <DeleteIcon />
                </StyledIconButton>
              </Box>
            </StyledTooltip>
          </Box>
        );
      }
    }
  ];

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "start" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            width: { xs: "95%", md: "98%" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 2
            }}
          >
            {/* Search input field */}
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                width: isMobile ? "100%" : "300px",
                height: "28px",
                borderRadius: 1,
                backgroundColor: "#f5f5f5",
                marginBottom: isMobile ? 2 : 0
              }}
            />
            <StyledButton
              onClick={handleOpenModal}
              variantType="primary"
              icon={<Add />}
            >
              Add Installer
            </StyledButton>
          </Box>
          <DataGrid
            rows={filteredInstallers}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              }
            }}
            pageSizeOptions={[5, 10, 25]}
            //checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              setSelected(newSelection.map((id) => String(id)));
            }}
            rowSelectionModel={selected}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1.5,
            backgroundColor: "#f5f5f5",
            mb: 1
          }}
        >
          <AddInstallerModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSnackbarChange={handleSnackbarChange}
            onSuccess={handleInstallerCreationSuccess}
          />
          <EditUserAccountStatusModal
            open={accountStatusModalOpen}
            user={currentEditUser}
            onClose={() => {
              setAccountStatusModalOpen(false);
              setCurrentEditUser(null);
            }}
            onSubmit={handleUpdateAccountStatus}
          />
          <EditUserModal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            user={currentEditUser}
            onSubmit={handleUpdateUser}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Dialog open={deleteModalOpen} onClose={handleClose}>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this installer?
              </Typography>
            </DialogContent>
            <DialogActions>
              <StyledButton onClick={handleClose}>Cancel</StyledButton>
              <StyledButton
                variantType="destructive"
                onClick={() => {
                  handleDelete();
                  handleClose();
                }}
              >
                Delete
              </StyledButton>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};

export default InstallersTable;
