import React from "react";
import { useTheme, Box, Typography, CircularProgress } from "@mui/material";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";
import {
  ErrorCircle48Regular,
  ArrowClockwise20Regular
} from "@fluentui/react-icons";

interface StepConnectingProps {
  isConnecting: boolean;
  error: string | null;
  pingAttempts: number;
  onRetry: () => void;
  retryAttempts: number;
  maxRetryAttempts: number;
}

const StepConnecting: React.FC<StepConnectingProps> = ({
  isConnecting,
  error,
  pingAttempts,
  onRetry,
  retryAttempts,
  maxRetryAttempts
}) => {
  const theme = useTheme();
  const hasReachedMaxRetries = retryAttempts >= maxRetryAttempts;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        py: 4
      }}
    >
      {isConnecting ? (
        <>
          <CircularProgress size={48} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1
            }}
          >
            <Typography variant="h6">Checking device connection...</Typography>
            <Typography color="text.secondary">
              Attempt {pingAttempts + 1} of 12
            </Typography>
          </Box>
          {retryAttempts > 1 && (
            <Typography variant="caption" color="text.secondary">
              Retry attempt {retryAttempts} of {maxRetryAttempts}
            </Typography>
          )}
        </>
      ) : error ? (
        <>
          <ErrorCircle48Regular primaryFill={theme.palette.error.main} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: theme.palette.error.main }}
              align="center"
            >
              Connection Failed
            </Typography>
            <Typography
              color="text.secondary"
              align="center"
              sx={{ maxWidth: 400 }}
            >
              {hasReachedMaxRetries
                ? "Maximum retry attempts reached. Please wait 2 hours and try again later."
                : error}
            </Typography>
          </Box>
          {!hasReachedMaxRetries && (
            <StyledButton
              variantType="primary"
              startIcon={<ArrowClockwise20Regular />}
              onClick={onRetry}
              sx={{ mt: 2 }}
            >
              Retry Connection
            </StyledButton>
          )}
        </>
      ) : (
        <>
          <CircularProgress size={48} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1
            }}
          >
            <Typography variant="h6">Connecting to Device</Typography>
            <Typography color="text.secondary">
              Please wait while we establish connection...
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default StepConnecting;
