import React from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider
} from "@mui/material";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";

interface NoSalesDistributorsModalProps {
  open: boolean;
  onClose: () => void;
  distributorsWithoutSales: { email: string; country?: string }[];
}

const NoSalesDistributorsModal: React.FC<NoSalesDistributorsModalProps> = ({
  open,
  onClose,
  distributorsWithoutSales
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          p: 3,
          background: "#fff",
          borderRadius: 2,
          width: "400px",
          margin: "auto",
          mt: 5,
          boxShadow: 3
        }}
      >
        <Typography variant="h6" gutterBottom textAlign="center">
          Distributors Without Sales
        </Typography>

        <Box
          sx={{
            maxHeight: "500px",
            overflowY: "auto",
            border: "1px solid #ddd",
            borderRadius: "8px",
            p: 1
          }}
        >
          <List dense>
            {distributorsWithoutSales.length > 0 ? (
              distributorsWithoutSales.map(({ email, country }, index) => (
                <React.Fragment key={email}>
                  <ListItem sx={{ py: 0.5 }}>
                    <ListItemText
                      primary={`${index + 1}. ${email} ${country ? `(${country})` : ""}`}
                      primaryTypographyProps={{ fontSize: "14px" }}
                    />
                  </ListItem>
                  {index !== distributorsWithoutSales.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <Typography sx={{ p: 1, textAlign: "center" }}>
                No distributors without sales
              </Typography>
            )}
          </List>
        </Box>

        <Box sx={{ textAlign: "center", mt: 2 }}>
          <StyledButton variantType="destructive" onClick={onClose}>
            Close
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default NoSalesDistributorsModal;
