import React from "react";
import { useTheme, Box, Typography } from "@mui/material";
import { CheckmarkCircle48Regular } from "@fluentui/react-icons";

interface StepSuccessProps {
  isEmailSent: boolean;
}

const StepSuccess: React.FC<StepSuccessProps> = ({ isEmailSent }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        py: 4
      }}
    >
      <CheckmarkCircle48Regular primaryFill={theme.palette.success.light} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: theme.palette.success.light }}
          align="center"
        >
          Device Setup Complete!
        </Typography>
        <Typography color="text.secondary" align="center">
          Your device has been successfully connected and configured
          {!isEmailSent && " Sending confirmation email..."}
        </Typography>
      </Box>
    </Box>
  );
};

export default StepSuccess;
