import { createTheme } from "@mui/material/styles";

const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
const fontFamily = isMac ? "Roboto, sans-serif" : "Segoe UI, sans-serif";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(30, 58, 138, 1)",
      light: "#0057A7",
      dark: "rgba(30, 58, 138, 1)",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#98BDEF",
      light: "#e3f2fd",
      dark: "#669ac9",
      contrastText: "#000000"
    },
    background: {
      default: "#f3f4f6",
      paper: "#ffffff"
    },
    text: {
      primary: "#212121",
      secondary: "#707070"
    }
  },
  typography: {
    fontSize: 14,
    fontWeightRegular: 400,
    fontFamily: "Hind"
  },
  components: {
    MuiListItemText: {
      // https://mui.com/material-ui/api/list-item-text/#css-api
      // Check what other styles to update if secondary are different
      styleOverrides: {
        root: {
          fontSize: 14
        },
        primary: {
          fontSize: 14
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          padding: "10px"
        }
      }
    },
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff"
        },
        columnHeader: {
          "& .MuiDataGrid-menuIcon": {
            width: "auto" // Ensure sufficient space for the icon
          },
          "& .MuiDataGrid-menuIconButton": {
            visibility: "visible", // Always show the icon
            opacity: 1, // Fully visible
            transition: "none" // Prevent hover-induced movement
          }
        }
      }
    }
  }
});
export default theme;
