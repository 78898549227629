import React from "react";
import { Box, Button, Typography, Chip } from "@mui/material";
import { Laptop, Smartphone } from "@mui/icons-material";

interface StepWifiSetupChoiceProps {
  onSelectSetupType: (type: "single" | "multiple") => void;
}

const StepWifiSetupChoice: React.FC<StepWifiSetupChoiceProps> = ({
  onSelectSetupType
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          How are you setting up this Lotus Pro device?
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Choose your setup method to get the appropriate instructions to
          connect your device to the WiFi network
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: { sm: "1fr", md: "1fr 1fr" }
        }}
      >
        <Button
          variant="outlined"
          size="large"
          sx={{
            textTransform: "none",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            p: 2
          }}
          onClick={() => onSelectSetupType("single")}
        >
          <Smartphone sx={{ fontSize: 24, mb: 1 }} />
          <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
            Setup with One Device
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Using only one phone or tablet
          </Typography>
        </Button>
        <Button
          variant="outlined"
          size="large"
          sx={{
            textTransform: "none",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            p: 2,
            position: "relative"
          }}
          onClick={() => onSelectSetupType("multiple")}
        >
          <Chip
            label="Recommended"
            color="primary"
            size="small"
            sx={{
              position: "absolute",
              top: -15,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1
            }}
          />
          <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
            <Laptop sx={{ fontSize: 24 }} />
            <Smartphone sx={{ fontSize: 24 }} />
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
            Setup with Two Devices
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Using a laptop and phone/tablet
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default StepWifiSetupChoice;
