import { Alert, Tooltip, useTheme } from "@mui/material";
import { Warning24Regular } from "@fluentui/react-icons";

type LowCartridgeAlertProps = {
  cyclesRemaining: number;
};

const LowCartridgeAlert: React.FC<LowCartridgeAlertProps> = ({
  cyclesRemaining
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#FFF4E5",
            border: "1px solid #e0e0e0"
          }
        }
      }}
      title={
        <Alert severity="warning">
          Warning: LOW CARTRIDGE LIFE. Cartridge Life is at{" "}
          {cyclesRemaining.toFixed(0)}%. Please replace it
        </Alert>
      }
    >
      <Warning24Regular primaryFill={theme.palette.warning.main} />
    </Tooltip>
  );
};

export default LowCartridgeAlert;
