import React from "react";
import { Tabs as MuiTabs, TabsProps, styled } from "@mui/material";

const CustomTabs = styled(MuiTabs)(({ theme }) => ({
  minHeight: "32px",
  "& .MuiTabs-indicator": {
    display: "none"
  },
  "& .MuiTab-root": {
    marginRight: "6px",
    "&:last-child": {
      marginRight: 0
    }
  }
}));

const StyledTabs: React.FC<TabsProps> = (props) => {
  return <CustomTabs variant="scrollable" scrollButtons="auto" {...props} />;
};

export default StyledTabs;
