import React from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

interface CustomTooltipProps extends TooltipProps {
  variant?: "primary" | "primary-dark" | "warn" | "error" | "black";
  opacity?: number;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props: {
  variant?: "primary" | "warn" | "error" | "primary-dark" | "black";
  opacity?: number;
}) => {
  const getVariantStyles = () => {
    switch (props.variant) {
      case "warn":
        return {
          backgroundColor: `rgba(245, 158, 11, ${props.opacity ?? 0.85})`,
          boxShadow: "0 4px 6px rgba(245, 158, 11, 0.2)",
          "& .MuiTooltip-arrow": {
            color: `rgba(245, 158, 11, ${props.opacity ?? 0.85})`
          }
        };
      case "error":
        return {
          backgroundColor: `rgba(220, 38, 38, ${props.opacity ?? 0.85})`,
          boxShadow: "0 4px 6px rgba(220, 38, 38, 0.2)",
          "& .MuiTooltip-arrow": {
            color: `rgba(220, 38, 38, ${props.opacity ?? 0.85})`
          }
        };
      case "primary-dark":
        return {
          backgroundColor: `rgba(30, 41, 59, ${props.opacity ?? 0.85})`,
          boxShadow: "0 4px 6px rgba(30, 41, 59, 0.2)",
          "& .MuiTooltip-arrow": {
            color: `rgba(30, 41, 59, ${props.opacity ?? 0.85})`
          }
        };
      case "black":
        return {
          backgroundColor: `rgba(0, 0, 0, ${props.opacity ?? 0.85})`,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
          "& .MuiTooltip-arrow": {
            color: `rgba(0, 0, 0, ${props.opacity ?? 0.85})`
          }
        };
      default:
        return {
          backgroundColor: `rgba(30, 58, 138, ${props.opacity ?? 0.85})`,
          boxShadow: "0 4px 6px rgba(30, 58, 138, 0.2)",
          "& .MuiTooltip-arrow": {
            color: `rgba(30, 58, 138, ${props.opacity ?? 0.85})`
          }
        };
    }
  };

  return {
    "& .MuiTooltip-tooltip": {
      ...getVariantStyles(),
      color: "#ffffff",
      fontSize: "0.75rem",
      borderRadius: "4px",
      backdropFilter: "blur(5px)"
    }
  };
});

const StyledTooltip: React.FC<CustomTooltipProps> = ({
  children,
  title,
  variant = "primary",
  opacity,
  ...props
}) => {
  return (
    <CustomTooltip
      title={title}
      arrow
      variant={variant}
      opacity={opacity}
      {...props}
    >
      {children}
    </CustomTooltip>
  );
};

export default StyledTooltip;
