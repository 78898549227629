import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  MenuItem,
  Divider,
  Typography,
  Grid,
  Autocomplete,
  FormHelperText,
  FormControl,
  Checkbox
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  ProductType,
  AccountType,
  SalesData,
  AccountsData
} from "../../../../common/types/DistributorSalesTypes";
import {
  createSalesReport,
  bulkAddSalesReports
} from "../../../../services/distributor.service";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";
import StyledTabs from "../../../../shared/Components/Styled/StyledTabs";
import StyledTab from "../../../../shared/Components/Styled/StyledTab";
import { StyledTextFieldNew } from "../../../../shared/Components/Styled/StyledTextFieldNew";
import UploadCsvModal from "./UploadSalesCsvModal";
import { allMonths as monthNames } from "../../../../common/types/constants";

type AccountOption = AccountsData | { accountName: string; label: string };

interface SalesInputFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (data: SalesData | SalesData[]) => void; // Callback after successful report creation
  onError: (message: string) => void; // Error handler callback
  distributorEmail: string;
  accounts: AccountsData[]; // Accounts list from the parent
  month: string;
  year: string;
}

const SalesInputForm: React.FC<SalesInputFormProps> = ({
  open,
  onClose,
  onSuccess,
  onError,
  distributorEmail,
  accounts,
  month,
  year
}) => {
  const getFirstAndLastDayOfMonth = (
    month: string
  ): { firstDay: Date; lastDay: Date } => {
    const monthIndex = monthNames.indexOf(month); // Get the zero-based index of the month
    const currentYear = Number(year);
    const firstDay = new Date(currentYear, monthIndex, 1); // First day of the month
    const lastDay = new Date(currentYear, monthIndex + 1, 0); // Last day of the month

    return { firstDay, lastDay };
  };

  // Get the first and last day of the selected month
  const { firstDay, lastDay } = getFirstAndLastDayOfMonth(month);
  const initialSalesReportState: SalesData = {
    product: ProductType.NONE,
    serialNumber: "",
    account: {
      accountType: AccountType.NONE,
      accountName: "",
      accountEmail: "",
      accountPhone: "",
      accountAddress: "",
      siteContact: ""
    },
    notes: "",
    date: firstDay, // Default to the first day of the month
    distributorEmail: distributorEmail
  };
  const [salesReport, setSalesReport] = useState<SalesData>(
    initialSalesReportState
  );
  //const [accounts, setAccounts] = useState<AccountsData[]>([]);
  const accountOptions: AccountOption[] = [
    { accountName: "New Account", label: "New Account" }, // Special option at the top
    ...accounts // Existing accounts from the API
  ];
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [quantity, setQuantity] = useState<number | null>(null); // Track the quantity for multiple entries
  const [activeTab, setActiveTab] = useState(0);
  const [isMultipleEntries, setIsMultipleEntries] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const resetForm = () => {
    setSalesReport(initialSalesReportState);
    setIsAddingAccount(false);
    setQuantity(null);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "quantity") {
      setQuantity(Number(value)); // Update quantity
    }

    if (name.startsWith("account.")) {
      const field = name.split(".")[1];
      setSalesReport((prev: SalesData) => ({
        ...prev,
        account: {
          ...prev.account,
          [field]: value
        }
      }));
    } else {
      setSalesReport((prev: SalesData) => ({ ...prev, [name]: value }));
    }
  };

  const handleDateChange = (date: any) => {
    setSalesReport((prev: SalesData) => ({ ...prev, date: date }));
  };

  // Function to validate serial numbers
  const validateSerialNumbers = () => {
    if (salesReport.product === ProductType.LOTUS_PRO && isMultipleEntries) {
      const serialNumbers = (salesReport.serialNumber ?? "")
        .split(",")
        .map((s) => s.trim());
      if (quantity && serialNumbers.length !== quantity) {
        return false; // Validation failed
      }
    }
    return true; // Validation passed
  };

  const handleSubmit = async () => {
    // Perform validation before submission
    if (!validateSerialNumbers()) {
      onError("The number of serial numbers must match the quantity.");
      return;
    }

    if (salesReport.product === ProductType.NONE) {
      onError("Please select a product.");
      return;
    }

    if (salesReport.account.accountType === AccountType.NONE) {
      onError("Please select an account type.");
      return;
    }

    if (isMultipleEntries && (!quantity || quantity <= 1)) {
      onError("Please enter a quantity greater than 1.");
      return;
    }

    if (
      !salesReport.account.accountPhone ||
      !salesReport.account.accountName ||
      !salesReport.account.accountEmail ||
      !salesReport.account.accountAddress ||
      !salesReport.account.siteContact
    ) {
      onError("Please fill in all account details.");
      return;
    }

    try {
      if (isMultipleEntries && quantity && quantity > 1) {
        // Use the bulk API for multiple entries
        const newSalesReports = await bulkAddSalesReports(
          salesReport,
          quantity
        );
        onSuccess(newSalesReports as SalesData[]); // Handle success with multiple reports
      } else {
        // Use the single create API for a single entry
        const newSalesReport = await createSalesReport(salesReport);
        onSuccess(newSalesReport); // Handle success with a single report
      }

      resetForm(); // Reset the form after successful submission
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error creating sales report:", error);
      onError(`Failed: ${(error as any).message}`);
    }
  };

  const handleNoSales = async () => {
    try {
      const noSalesReport = await createSalesReport({
        ...salesReport,
        product: ProductType.NONE
      });
      onSuccess(noSalesReport); // Handle success with a single report
      resetForm(); // Reset the form after successful submission
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error creating no sales report:", error);
      onError(`Failed: ${(error as any).message}`);
    }
  };

  // Reset form when the modal closes
  const handleClose = () => {
    resetForm();
    onClose(); // Make sure the dialog is closed
  };

  // Function to update the form data based on selected account
  const populateFormFields = (account: AccountsData) => {
    setSalesReport((prev: SalesData) => ({
      ...prev,
      account: {
        ...prev.account,
        accountName: account.accountName,
        accountEmail: account.accountEmail,
        accountPhone: account.accountPhone,
        accountAddress: account.accountAddress,
        siteContact: account.siteContact
      }
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", pb: 2 }}
      >
        Add New Sales Report for {month}
      </DialogTitle>

      <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
        <StyledTabs value={activeTab} onChange={handleTabChange}>
          <StyledTab label="No Sales" />
          <StyledTab
            label="Single Sale"
            onClick={() => setIsMultipleEntries(false)}
          />
          <StyledTab
            label="Multiple Sales"
            onClick={() => setIsMultipleEntries(true)}
          />
          <StyledTab label="Upload CSV" />
        </StyledTabs>
      </Box>
      {activeTab === 0 && (
        <DialogContent sx={{ backgroundColor: "#fafafa", py: 3 }}>
          <Typography variant="h6" gutterBottom color="primary">
            No Sales Confirmation
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={isAcknowledged}
              onChange={(e) => setIsAcknowledged(e.target.checked)}
            />
            <Typography variant="body1" gutterBottom>
              Check this box to confirm no sales for this month.
            </Typography>
          </Box>
          <DialogActions sx={{ backgroundColor: "#f5f5f5", pt: 2 }}>
            <StyledButton onClick={handleClose} variantType="outline-red">
              Cancel
            </StyledButton>
            <StyledButton
              onClick={handleNoSales}
              variantType="primary"
              disabled={!isAcknowledged}
            >
              Submit
            </StyledButton>
          </DialogActions>
        </DialogContent>
      )}
      {(activeTab === 1 || activeTab === 2) && (
        <>
          <DialogContent sx={{ backgroundColor: "#fafafa", py: 3 }}>
            <Box component="form" noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom color="primary">
                Product Information
              </Typography>

              <Grid container spacing={1} mb={1}>
                <Grid item xs={10} sm={4}>
                  <TextField
                    select
                    fullWidth
                    required
                    label="Product Type"
                    name="product"
                    value={salesReport.product}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      sx: {
                        "& .MuiInputLabel-asterisk": {
                          color: "red"
                        }
                      }
                    }}
                  >
                    {Object.values(ProductType).map((option) => (
                      <MenuItem key={option as string} value={option as string}>
                        {option as string}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {isMultipleEntries && (
                  <Grid item xs={2} sm={2}>
                    <TextField
                      fullWidth
                      label="Quantity"
                      name="quantity"
                      type="number"
                      onChange={handleChange}
                      margin="normal"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                )}
                {salesReport.product === ProductType.LOTUS_PRO && (
                  <Grid item xs={12} sm={isMultipleEntries ? 6 : 8}>
                    <TextField
                      fullWidth
                      label="Serial Number"
                      name="serialNumber"
                      value={salesReport.serialNumber}
                      onChange={handleChange}
                      margin="normal"
                      variant="outlined"
                      size="small"
                      helperText={
                        isMultipleEntries
                          ? "Enter multiple serial numbers separated by commas"
                          : ""
                      }
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl sx={{ width: "100%" }} margin="none" size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      views={["year", "month", "day"]}
                      value={dayjs(salesReport.date) || null}
                      minDate={dayjs(firstDay)} // Lock the start to the first day of the month
                      maxDate={dayjs(lastDay)} // Lock the end to the last day of the month
                      onChange={handleDateChange}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px"
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Divider sx={{ my: 1 }} />

              <Typography variant="h6" gutterBottom color="primary">
                Account Information
              </Typography>

              <Grid container spacing={1} mb={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    required
                    label="Account Type"
                    name="account.accountType"
                    value={salesReport.account.accountType}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      sx: {
                        "& .MuiInputLabel-asterisk": {
                          color: "red"
                        }
                      }
                    }}
                  >
                    {Object.values(AccountType).map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={accountOptions.filter(
                      (option) =>
                        option.accountName === "New Account" || // Always include "New Account"
                        ("accountType" in option &&
                          option.accountType ===
                            salesReport.account.accountType)
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={
                          "label" in option ? option.label : option.accountName
                        }
                        style={{
                          fontWeight: "label" in option ? 700 : 400, // Bold font for "New Account"
                          color:
                            "label" in option
                              ? "rgba(30, 58, 138, 1)"
                              : "inherit" // Blue color for "New Account"
                        }}
                        className={props.className}
                      >
                        {"label" in option
                          ? option.label
                          : `${option.accountName} - ${option.accountEmail}`}
                      </li>
                    )}
                    getOptionLabel={(option) =>
                      "label" in option ? option.label : option.accountName
                    }
                    isOptionEqualToValue={(option, value) => {
                      if ("label" in option && "label" in value) {
                        return option.label === value.label;
                      }
                      return option.accountName === value.accountName;
                    }}
                    value={
                      isAddingAccount
                        ? { accountName: "", label: "New Account" }
                        : accounts.find(
                            (c) =>
                              c.accountName === salesReport.account.accountName
                          ) || null
                    }
                    onChange={(_, newValue) => {
                      if (
                        newValue &&
                        "label" in newValue &&
                        newValue.label === "New Account"
                      ) {
                        // Handle "New Account" selection
                        setIsAddingAccount(true);
                        setSalesReport((prev) => ({
                          ...prev,
                          account: {
                            accountType: salesReport.account.accountType,
                            accountName: "",
                            accountEmail: "",
                            accountPhone: "",
                            accountAddress: "",
                            siteContact: ""
                          }
                        }));
                      } else if (newValue && !("label" in newValue)) {
                        // Handle existing account selection
                        setIsAddingAccount(false);
                        populateFormFields(newValue); // Populate the form with account details
                      } else {
                        // Handle case where selection is cleared
                        setIsAddingAccount(false);
                        setSalesReport((prev) => ({
                          ...prev,
                          account: {
                            accountType: AccountType.NONE,
                            accountName: "",
                            accountEmail: "",
                            accountPhone: "",
                            accountAddress: "",
                            siteContact: ""
                          }
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <StyledTextFieldNew
                        {...params}
                        label="Account"
                        name="account"
                        required
                        fullWidth
                        margin="normal"
                      />
                    )}
                    size="small"
                  />
                  <FormHelperText>
                    Select an already existing account or create a new account
                  </FormHelperText>{" "}
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    label="Account Name"
                    name="account.accountName"
                    value={salesReport.account.accountName}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    disabled={!isAddingAccount}
                    InputLabelProps={{
                      sx: {
                        "& .MuiInputLabel-asterisk": {
                          color: "red"
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    label="Account Email"
                    name="account.accountEmail"
                    value={salesReport.account.accountEmail}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    disabled={!isAddingAccount}
                    InputLabelProps={{
                      sx: {
                        "& .MuiInputLabel-asterisk": {
                          color: "red"
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    label="Account Phone"
                    name="account.accountPhone"
                    value={salesReport.account.accountPhone}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    disabled={!isAddingAccount}
                    InputLabelProps={{
                      sx: {
                        "& .MuiInputLabel-asterisk": {
                          color: "red"
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    required
                    label="Account Address"
                    name="account.accountAddress"
                    value={salesReport.account.accountAddress}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    disabled={!isAddingAccount}
                    InputLabelProps={{
                      sx: {
                        "& .MuiInputLabel-asterisk": {
                          color: "red"
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    label="Site Contact"
                    name="account.siteContact"
                    value={salesReport.account.siteContact}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    disabled={!isAddingAccount}
                    InputLabelProps={{
                      sx: {
                        "& .MuiInputLabel-asterisk": {
                          color: "red"
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ my: 1 }} />

              <Typography variant="h6" gutterBottom color="primary">
                Additional Information
              </Typography>
              <Grid container spacing={1} mb={1}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Notes"
                    name="notes"
                    value={salesReport.notes}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: "#f5f5f5", pt: 2 }}>
            <StyledButton onClick={handleClose} variantType="outline-red">
              Cancel
            </StyledButton>
            <StyledButton onClick={handleSubmit} variantType="primary">
              Submit
            </StyledButton>
          </DialogActions>
        </>
      )}
      {activeTab === 3 && (
        <UploadCsvModal
          onUploadSuccess={(_csvData: SalesData[], errorMessage?: string) => {
            if (errorMessage) {
              onError(errorMessage);
            } else {
              onSuccess(_csvData);
            }
          }}
          distributorEmail={distributorEmail}
          onClose={handleClose}
        />
      )}
    </Dialog>
  );
};

export default SalesInputForm;
