import React, { useState } from "react";
import {
  InputBase,
  Box,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { ICleanDevice } from "../../../common/types/DeviceTypes";
import { Search20Regular } from "@fluentui/react-icons";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  searchIcon: {
    position: "absolute",
    color: "primary.contrastText",
    width: "20px",
    height: "20px",
    cursor: "pointer"
  }
});

type SearchBarProps = {
  devices: ICleanDevice[];
  onDeviceSelect?: (device: ICleanDevice) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({ devices, onDeviceSelect }) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchedDevices, setSearchedDevices] = useState<ICleanDevice[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile screen size
  const styles = useStyles();

  const filterDevices = (query: string) => {
    query = query.toLowerCase();
    setSearchedDevices(
      devices.filter((device) => {
        let deviceName = device.deviceInformation.deviceName.toLowerCase();
        let deviceId = device.deviceInformation.deviceId;
        return deviceName.includes(query) || deviceId.includes(query);
      })
    );
  };

  const handleDeviceSelect = (device: ICleanDevice) => {
    if (onDeviceSelect) onDeviceSelect(device);
    setSearchText("");
    setSearchedDevices([]);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchedDevices.length > 0) {
      handleDeviceSelect(searchedDevices[0]);
    }
  };

  return (
    <>
      <Box
        onClick={() => setFocused(true)}
        sx={{
          cursor: "pointer",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: focused ? "flex-start" : "center",
          width: focused ? (isMobile ? 120 : 280) : 24,
          padding: 1.5,
          transition: "width 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)"
          }
        }}
      >
        <Search20Regular className={styles.searchIcon} />
        <InputBase
          placeholder="Search device…"
          onFocus={() => setFocused(true)}
          onBlur={() => {
            // Temporary fix to prevent the search bar from closing when clicking on the search results
            setTimeout(() => {
              setFocused(false);
              setSearchText("");
            }, 100);
          }}
          sx={{
            color: focused ? "primary.contrastText" : "transparent",
            pl: focused ? 5 : 0,
            pr: focused ? 5 : 0,
            width: focused ? "100%" : 24,
            border: "none",
            "&::placeholder": {
              color: "rgba(255, 255, 255, 0.1)"
            },
            "&:focus": {
              backgroundColor: "rgba(255, 255, 255, 0.1)"
            }
          }}
          value={searchText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
            filterDevices(event.target.value);
          }}
          onKeyPress={handleKeyPress}
        />
      </Box>
      {searchText && (
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "rgba(255, 255, 255, 1)",
            color: "black",
            border: "1px solid rgba(255, 255, 255, 0.25)",
            borderRadius: "10px",
            marginTop: 1,
            width: focused ? 400 : 280,
            maxHeight: 200,
            overflowY: "auto",
            overflowX: "hidden",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <List sx={{ width: "100%" }}>
            {searchedDevices.length > 0 ? (
              searchedDevices.map((device, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ borderBottom: "1px solid #e0e0e0" }}
                >
                  <ListItemButton
                    sx={{
                      marginX: "auto"
                    }}
                    onClick={() => handleDeviceSelect(device)}
                  >
                    <ListItemText
                      primary={`${device.deviceInformation.deviceName} (${device.deviceInformation.deviceId})`}
                    />
                    <Badge
                      color="primary"
                      badgeContent="Device"
                      sx={{ p: 0, mr: 2 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No devices found" />
              </ListItem>
            )}
          </List>
        </Box>
      )}
    </>
  );
};

export default SearchBar;
