import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Box
} from "@mui/material";
import { SalesData } from "../../../common/types/DistributorSalesTypes";

interface SalesSummaryCardProps {
  year: string;
  distributor: string;
  sales: SalesData[];
}

export default function SalesSummaryCard({
  year,
  distributor,
  sales
}: SalesSummaryCardProps) {
  const [totalSalesCount, setTotalSalesCount] = useState(0);
  const [monthsWithSales, setMonthsWithSales] = useState(0);

  useEffect(() => {
    // Filter sales data for the specified year and distributor
    const filteredSales = sales.filter(
      (s) =>
        new Date(s.date || "").getFullYear().toString() === year &&
        (distributor === "All" || s.distributorEmail === distributor)
    );

    // Count total number of sales
    const totalSales = filteredSales.filter((s) => s.product !== "NONE").length;

    // Map sales to months
    const monthSet = new Set<string>(); // Tracks months with sales data
    filteredSales.forEach((s) => {
      const monthName = new Date(s.date || "").toLocaleString("default", {
        month: "long"
      });
      monthSet.add(monthName); // Add the month to the Set
    });

    // Update state
    setTotalSalesCount(totalSales);
    setMonthsWithSales(monthSet.size); // Count unique months with sales data
  }, [year, distributor, sales]);

  return (
    <Card sx={{ mt: 0, p: 0, boxShadow: 3 }}>
      <CardHeader
        title={
          <Typography variant="h6" fontWeight="bold" color="primary">
            Sales Summary
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Total Number of Sales
              </Typography>
              <Typography variant="h5" fontWeight="bold" color="primary">
                {totalSalesCount.toLocaleString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Reported Months
              </Typography>
              <Typography variant="h5" fontWeight="bold" color="primary">
                {monthsWithSales} / 12
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
