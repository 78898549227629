import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  MenuItem,
  Alert,
  Snackbar,
  AlertColor,
  useTheme,
  useMediaQuery,
  TextField,
  Select,
  InputLabel,
  SelectChangeEvent,
  FormControl,
  Modal
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { AccountType } from "../../common/types/DistributorSalesTypes";
import useAuthStore from "../../store/authStore";
import StyledButton from "../../shared/Components/Styled/StyledButton";
import GenerateSalesReport from "./GenerateReport/GenerateSalesReport";
import SalesReportHistory from "./History/SalesReportHistory";
import MonthlySalesReportTable from "./Sales/Table/MonthlySalesReportTable";
import { useDistributorAccounts } from "./Hooks/useDistributorAccounts";
import { useDistributorSales } from "./Hooks/useDistributorSales";
import useDistributorStore from "../../store/useDistributor";
import MonthGrid from "./Sales/MonthGrid";
import SalesSummaryCard from "./Sales/SalesSummaryCard";
import StyledIconButton from "../../shared/Components/Styled/StyledIconButton";
import StyledTabs from "../../shared/Components/Styled/StyledTabs";
import StyledTab from "../../shared/Components/Styled/StyledTab";
import ProductSalesSummary from "./Sales/Modal/ProductSalesSummary";
import NoSalesDistributorsModal from "./Sales/Modal/NoSalesDistributorsModal";
import { allDistributorEmails } from "../../common/types/constants";
import Helper from "../Shared/Helper";

const MonthlySalesReport: React.FC<{}> = () => {
  const { accounts, fetchAccounts } = useDistributorAccounts();
  const { sales, fetchSales } = useDistributorSales();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [distributorFilter, setDistributorFilter] = useState<string>("All");
  const [countryFilter, setCountryFilter] = useState<string>("");
  const [accountTypeFilter, setAccountTypeFilter] = useState<string>("");
  const [openViewAllSales, setOpenViewAllSales] = useState(false);
  const [openSalesReports, setOpenSalesReports] = useState(false);
  const [openProductSalesSummary, setOpenProductSalesSummary] = useState(false);

  const [showNoSales, setShowNoSales] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({
    open: false,
    message: "",
    severity: undefined
  });

  const { Distributors, fetchDistributors } = useDistributorStore();

  const { user, userRole } = useAuthStore();
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());

  const handleClose = () => {
    setOpenViewAllSales(false);
  };

  useEffect(() => {
    fetchAccounts();
    fetchSales();
    fetchDistributors();
  }, [fetchAccounts, fetchSales]);

  const handleSubmit = (isSuccess: boolean) => {
    if (isSuccess) {
      setSnackbar({
        open: true,
        message: "Report generated successfully",
        severity: "success"
      });
    } else {
      setSnackbar({
        open: true,
        message: "Failed to generate sales report",
        severity: "error"
      });
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCountryFilterChange = (event: SelectChangeEvent<string>) => {
    setCountryFilter(event.target.value);
  };
  const handleAccountTypeFilterChange = (event: SelectChangeEvent<string>) => {
    setAccountTypeFilter(event.target.value);
  };

  const handleCloseViewAllSales = () => {
    setOpenViewAllSales(false);
  };

  const handleCloseSalesReports = () => {
    setOpenSalesReports(false);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  // Get unique distributors from sales
  const distributorsWithSales = new Map(
    sales.map((sale) => [
      sale.distributorEmail.toLowerCase(),
      { email: sale.distributorEmail, country: sale.distributorCountry }
    ])
  ).values();

  // Convert to an array
  const distributorsWithSalesArray = [...distributorsWithSales];

  // Find distributors from the full list who have NO sales
  const distributorsWithoutSales = allDistributorEmails
    .filter(
      (email) =>
        !distributorsWithSalesArray.some(
          (d) => d.email.toLowerCase() === email.toLowerCase()
        )
    )
    .map((email) => ({ email })); // Ensure it's in the same format

  const allDistributorsWithoutSales = Distributors.filter(
    (distributor: { email: string; country: string }) =>
      // Check if distributor email is in allDistributorEmails AND they have no sales
      allDistributorEmails.includes(distributor.email.toLowerCase()) &&
      !distributorsWithSalesArray.some(
        (d) => d.email.toLowerCase() === distributor.email.toLowerCase()
      )
  ).map(({ email, country }: { email: string; country: string }) => ({
    email,
    country
  }));

  // Combine the lists and sort them alphabetically by email
  const sortedDistributors = [
    ...distributorsWithSalesArray,
    ...allDistributorsWithoutSales
  ].sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()));

  return (
    <Box sx={{ p: 0 }}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" }
          }}
        >
          <Typography sx={{ mb: 2 }} variant="h6">
            Distributor Monthly Sales Reporting
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: isMobile ? 1 : 0,
                flexDirection: isMobile ? "column" : "row"
              }}
            >
              <FormControl variant="outlined" size="small">
                <InputLabel>Select Year</InputLabel>
                <Select
                  label="Select Year"
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{ width: "200px", mr: 2 }}
                >
                  {Array.from({ length: 5 }, (_, i) => (
                    <MenuItem
                      key={i}
                      value={(new Date().getFullYear() - i).toString()}
                    >
                      {(new Date().getFullYear() - i).toString()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {userRole === "Admin" && (
                <FormControl variant="outlined" size="small">
                  <InputLabel>Select Distributor</InputLabel>
                  <Select
                    label="Select Distributor"
                    name="distributor"
                    value={distributorFilter}
                    onChange={(e) => setDistributorFilter(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ width: "300px" }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {sortedDistributors.map(({ email, country }) => (
                      <MenuItem key={email} value={email}>
                        {email} ({country || "Unknown"})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {userRole === "Admin" && (
                <StyledButton
                  variantType="destructive"
                  onClick={() => setShowNoSales(true)}
                  sx={{
                    ml: 2,
                    backgroundColor: "#ef5350",
                    // border: "1px solid rgba(100, 0, 0, 0.3)",
                    // color: "#b71c1c",
                    fontWeight: 500
                  }}
                >
                  View Unreported Distributors
                </StyledButton>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: isMobile ? 2 : 0,
                gap: 1
              }}
            >
              {userRole === "Admin" && (
                <StyledButton
                  variantType="outline-primary"
                  onClick={() => setOpenSalesReports(true)}
                >
                  Reports
                </StyledButton>
              )}
              <StyledButton
                variantType="outline-primary"
                onClick={() => setOpenProductSalesSummary(true)}
              >
                Sales Summary
              </StyledButton>
              <StyledButton
                variantType="primary"
                onClick={() => setOpenViewAllSales(true)}
              >
                View All Sales
              </StyledButton>
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <MonthGrid
              sales={sales}
              year={year}
              distributor={distributorFilter}
              accounts={accounts}
              distributorEmail={user?.email || ""}
              setSnackbar={setSnackbar}
              refetchSales={fetchSales}
            />
            <SalesSummaryCard
              year={year}
              distributor={distributorFilter}
              sales={sales}
            />
            <Helper
              title="View Helper"
              description="Watch our help video on how to make a sales report or what to do
              when there are no sales. Need some help?"
              link="https://tersanocanada-my.sharepoint.com/:v:/g/personal/emmanuelj_tersano_com/ESc7_DUmdrtGj8KL3tpsTd8Br6je42Wt_nGDvCYSoB1giw?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=Ud7wuM&referrer=Outlook.Web&referrerScenario=email-linkwithoutembed"
            />
          </Box>

          <ProductSalesSummary
            open={openProductSalesSummary}
            year={year}
            distributorFilter={distributorFilter}
            sales={sales}
            onClose={() => {
              setOpenProductSalesSummary(false);
            }}
          />

          <Modal open={openViewAllSales} onClose={handleCloseViewAllSales}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: 2,
                p: 2,
                maxWidth: isMobile ? "95%" : "80%",
                maxHeight: "85%",
                overflow: "hidden" // Allow scrolling for content
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography variant="h6">All Sales Reports</Typography>

                <StyledIconButton
                  variantType="destructive"
                  color="error"
                  onClick={handleClose}
                >
                  <Close />
                </StyledIconButton>
              </Box>
              <Box
                sx={{
                  display: isMobile ? "flex-column" : "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  p: 0,
                  mt: 2
                }}
              >
                <Box sx={{ display: "flex", gap: 1.5 }}>
                  {/* Search input field */}
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{
                      width: isMobile ? "100%" : "300px",
                      borderRadius: 1,
                      backgroundColor: "#f5f5f5",
                      marginBottom: isMobile ? 2 : 0,
                      "& .MuiOutlinedInput-root": {
                        height: "32px"
                      }
                    }}
                  />
                  {userRole === "Admin" && (
                    <>
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "170px",

                          "& .MuiOutlinedInput-root": {
                            height: "32px"
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "8px 14px"
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "0.875rem"
                          }
                        }}
                      >
                        <InputLabel>Account Type</InputLabel>
                        <Select
                          value={accountTypeFilter}
                          onChange={handleAccountTypeFilterChange}
                          variant="outlined"
                          name="accountType"
                          label="Account Type"
                        >
                          <MenuItem value="">All Accounts</MenuItem>
                          {Object.values(AccountType).map((acc) => (
                            <MenuItem key={acc} value={acc}>
                              {acc}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "170px",

                          "& .MuiOutlinedInput-root": {
                            height: "32px"
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "8px 14px"
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "0.875rem"
                          }
                        }}
                      >
                        <InputLabel>Country</InputLabel>
                        <Select
                          value={countryFilter}
                          onChange={handleCountryFilterChange}
                          variant="outlined"
                          name="country"
                          label="Country"
                        >
                          <MenuItem value="">All Countries</MenuItem>
                          {[
                            ...new Set(
                              sales.map((sale) => sale.distributorCountry)
                            )
                          ].map((country) => (
                            <MenuItem key={country} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Box>
              </Box>
              <Box sx={{ mt: 2, overflow: "auto", maxHeight: "70vh" }}>
                {/* Monthly Sales Reporting Table */}
                <MonthlySalesReportTable
                  accountTypeFilter={accountTypeFilter}
                  countryFilter={countryFilter}
                  searchTerm={searchTerm}
                  setSnackbar={setSnackbar}
                  sales={sales}
                  accounts={accounts}
                  onDeleteSuccess={fetchSales}
                  onEditSuccess={() => {
                    fetchSales();
                    fetchAccounts();
                  }}
                />
              </Box>
            </Box>
          </Modal>

          <Modal open={openSalesReports} onClose={handleCloseSalesReports}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: 2,
                p: 2,
                maxWidth: isMobile ? "95%" : "80%",
                width: "80%",
                height: "80%",
                maxHeight: "85%",
                overflow: "hidden" // Allow scrolling for content
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography variant="h6">Sales Reports</Typography>

                <StyledIconButton
                  variantType="destructive"
                  color="error"
                  onClick={handleCloseSalesReports}
                >
                  <Close />
                </StyledIconButton>
              </Box>
              <Box sx={{ mt: 2 }}>
                <StyledTabs value={selectedTab} onChange={handleTabChange}>
                  <StyledTab label="Generate Report" />
                  <StyledTab label="History" />
                </StyledTabs>
              </Box>
              <>
                {selectedTab === 0 && userRole === "Admin" && (
                  <GenerateSalesReport sales={sales} onSubmit={handleSubmit} />
                )}

                {selectedTab === 1 && userRole === "Admin" && (
                  <SalesReportHistory />
                )}
              </>
            </Box>
          </Modal>

          {/* No Sales Modal */}
          <NoSalesDistributorsModal
            open={showNoSales}
            onClose={() => setShowNoSales(false)}
            distributorsWithoutSales={distributorsWithoutSales}
          />
        </Box>
      </Box>
      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MonthlySalesReport;
