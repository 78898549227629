import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Container,
  Link
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StyledTabs from "../shared/Components/Styled/StyledTabs";
import StyledTab from "../shared/Components/Styled/StyledTab";
import whiteLogo from "../assets/images/tersanowhite.png";
import StyledButton from "../shared/Components/Styled/StyledButton";

type Role = "Distributor" | "Customer" | "Staff";

const CreateAccount: React.FC = () => {
  const [role, setRole] = useState<Role>("Customer");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info" as "success" | "info" | "warning" | "error"
  });
  const navigate = useNavigate();

  const handleRoleChange = (event: React.SyntheticEvent, newValue: Role) => {
    setRole(newValue);
  };

  const handleCreateAccount = async () => {
    if (password !== confirmPassword) {
      setSnackbar({
        open: true,
        message: "Passwords do not match.",
        severity: "error"
      });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        {
          firstName,
          lastName,
          email,
          password,
          role
        }
      );
      if (response.data) {
        setSnackbar({
          open: true,
          message: "Account created successfully. Please log in.",
          severity: "success"
        });
        navigate("/login");
      }
    } catch (error: any) {
      setSnackbar({
        open: true,
        message: error.response?.data?.details || "Failed to create account.",
        severity: "error"
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
        background:
          "linear-gradient(45deg, rgba(30, 58, 138, 1) 55%, rgba(30, 58, 138, 0.6) 90%)"
      }}
    >
      <Box
        sx={{
          m: 1,
          ml: 2,
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mb: 10
        }}
      >
        <img
          src={whiteLogo}
          alt="Company Logo"
          style={{
            // position: "absolute", // Absolute position
            // top: 40, // 10px from the top
            // left: 40, // 10px from the left
            width: 120, // Adjust size as necessar
            marginBottom: "40px"
          }}
        />
        <Grid
          container
          spacing={1}
          sx={{
            padding: "1.5rem",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)"
          }}
        >
          <Container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mb: 2
            }}
          >
            <StyledTabs
              value={role}
              onChange={handleRoleChange}
              aria-label="Role tabs"
            >
              <StyledTab label="Distributor" value="Distributor" />
              <StyledTab label="Customer" value="Customer" />
              <StyledTab label="Staff" value="Staff" />
            </StyledTabs>
          </Container>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              textAlign="center"
              sx={{ fontWeight: 600, color: "grey.900", mt: 2 }}
            >
              Create {role} Account
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              onChange={(e) => setFirstName(e.target.value)}
              autoFocus
              sx={{
                ".MuiInputLabel-root": { fontSize: "0.875rem" },
                ".MuiInputBase-input": { fontSize: "0.875rem" }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              onChange={(e) => setLastName(e.target.value)}
              sx={{
                ".MuiInputLabel-root": { fontSize: "0.875rem" },
                ".MuiInputBase-input": { fontSize: "0.875rem" }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                ".MuiInputLabel-root": { fontSize: "0.875rem" },
                ".MuiInputBase-input": { fontSize: "0.875rem" }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                ".MuiInputLabel-root": { fontSize: "0.875rem" },
                ".MuiInputBase-input": { fontSize: "0.875rem" }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{
                ".MuiInputLabel-root": { fontSize: "0.875rem" },
                ".MuiInputBase-input": { fontSize: "0.875rem" }
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <Link
              href="/login"
              variant="body2"
              sx={{
                "&:hover": {
                  transform: "scale(1.5)" // subtle zoom out effect
                }
              }}
            >
              Already have an account? Login{" "}
            </Link>
          </Grid>
          <Grid item xs={6} sx={{ alignSelf: "center", textAlign: "right" }}>
            <StyledButton
              type="submit"
              fullWidth
              variantType="primary"
              size="large"
              onClick={handleCreateAccount}
            >
              Create Account
            </StyledButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateAccount;
