import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { CompoundButton } from "@fluentui/react-components";
import {
  DeviceMeetingRoomRemoteRegular,
  WifiSettingsRegular
} from "@fluentui/react-icons";
import InstallationModal from "./Modals/InstallationModal";
import ReconnectModal from "./Modals/ReconnectModal";
import Helper from "../Shared/Helper";

const InstallationWizard: React.FC = () => {
  const [isInstallationModalOpen, setIsInstallationModalOpen] = useState(false);
  const [isReconnectModalOpen, setIsRecconectModalOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: 8,
        flexDirection: "column",
        alignItems: "center",
        gap: 2
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            color: "grey.900"
          }}
        >
          Tersano Lotus Pro Installation
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            color: "grey.800"
          }}
        >
          Welcome! Let's get your device set up. Please select the option below
          to begin
        </Typography>
      </Box>

      <CompoundButton
        icon={<DeviceMeetingRoomRemoteRegular />}
        secondaryContent="Register a new unit on Portal"
        size="large"
        onClick={() => setIsInstallationModalOpen(true)}
        style={{ width: 300 }}
      >
        New Device
      </CompoundButton>

      <CompoundButton
        icon={<WifiSettingsRegular />}
        secondaryContent="Change Wi-Fi network for Device"
        size="large"
        onClick={() => setIsRecconectModalOpen(true)}
        style={{ width: 300 }}
      >
        Reconnect Device
      </CompoundButton>

      <Helper
        title="Need help?"
        description="Watch our help video on how to install and configure a device. Need some help?"
        link="https://tersanocanada-my.sharepoint.com/:v:/g/personal/hassanu_tersano_com/EdG2tF62afJGovHYS3bXhmIBf0KSHOAk76XOI8IV6eEQFg?e=qvjYOE"
      />

      {isInstallationModalOpen && (
        <InstallationModal
          isOpen={true}
          onClose={() => setIsInstallationModalOpen(false)}
        />
      )}

      {isReconnectModalOpen && (
        <ReconnectModal
          isOpen={true}
          onClose={() => setIsRecconectModalOpen(false)}
        />
      )}
    </Box>
  );
};

export default InstallationWizard;
