import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import type { InstallationStep } from "../../../common/types/InstallDeviceTypes";

interface StepNavigationProps {
  step: InstallationStep;
  isLoading: boolean;
  onBack: () => void;
  onContinue: () => void;
  canContinue: boolean;
  isDeviceCreated?: boolean;
  isEmailSent?: boolean;
  customerEmail?: string;
  error: any;
}

const StepNavigation: React.FC<StepNavigationProps> = ({
  step,
  isLoading,
  onBack,
  onContinue,
  canContinue,
  isDeviceCreated,
  isEmailSent,
  customerEmail,
  error
}) => {
  const shouldShowBack = (() => {
    if (
      step === "serial" ||
      step === "success" ||
      step === "connecting" ||
      step === "wifi-setup-choice"
    )
      return false;
    if (step === "device-created" && !error) return false;
    return true;
  })();

  const shouldShowContinue = (() => {
    if (step === "wifi-setup-choice" || step === "connecting") return false;
    if (step === "device-created" && error) return false;
    return true;
  })();

  const shouldShowSkip = (() => {
    // Only show skip for customer step
    if (step === "customer") return true;
    return false;
  })();

  const isButtonDisabled = (() => {
    if (step === "connecting" && isDeviceCreated) return false;
    if (step === "success" && !isEmailSent) return false;
    // For customer step, enable continue only if email is selected
    if (step === "customer") return !customerEmail;
    return !canContinue || isLoading;
  })();

  const isSkipButtonDisabled = (() => {
    // For customer step, disable skip if email is selected
    if (step === "customer") return customerEmail !== "";
    return true;
  })();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "16px"
      }}
    >
      {shouldShowBack && (
        <StyledButton
          variantType="outline-primary"
          onClick={onBack}
          icon={<ArrowLeftRegular />}
        >
          Back
        </StyledButton>
      )}
      {shouldShowContinue && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, ml: "auto" }}>
          {shouldShowSkip && (
            <StyledButton
              variantType="outline-primary"
              onClick={onContinue}
              disabled={isSkipButtonDisabled}
            >
              Skip
            </StyledButton>
          )}
          <StyledButton
            variantType="primary"
            onClick={onContinue}
            disabled={isButtonDisabled}
          >
            {step === "success" ? "Done" : "Continue"}
            {isLoading && (
              <CircularProgress size={20} sx={{ ml: 1, color: "white" }} />
            )}
          </StyledButton>
        </Box>
      )}
    </div>
  );
};

export default StepNavigation;
