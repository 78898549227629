import React from "react";
import { Box, Typography } from "@mui/material";
import StyledButton from "../../shared/Components/Styled/StyledButton";

type NoDeviceMessageProps = {
  message: string;
};

const NoDeviceMessage: React.FC<NoDeviceMessageProps> = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1,
        height: "50vh"
      }}
    >
      <Typography variant="h6" color="primary">
        Oops! You have no devices installed yet.
      </Typography>
      <Typography color="text.secondary">{message}</Typography>
      <StyledButton
        variantType="primary"
        onClick={() => {
          localStorage.setItem("activeTab", "install");
          window.location.reload();
        }}
        sx={{ mt: 2, "&:hover": { color: "white" } }}
      >
        Install a Device
      </StyledButton>
    </Box>
  );
};

export default NoDeviceMessage;
