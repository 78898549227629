import React from "react";
import { Box, TextField, Typography, Autocomplete } from "@mui/material";
import type { InstallationData } from "../../../../common/types/InstallDeviceTypes";
import { countryLabels } from "../../../../common/types/constants";

interface StepLocationProps {
  data: InstallationData;
  updateData: (fields: Partial<InstallationData>) => void;
}

const StepLocation: React.FC<StepLocationProps> = ({ data, updateData }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold"
        }}
      >
        Device Location Details
      </Typography>
      <Autocomplete
        options={countryLabels}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        value={data.location.country ? { label: data.location.country } : null}
        onChange={(_e, value) =>
          updateData({
            location: { ...data.location, country: value?.label || "" }
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            fullWidth
            margin="normal"
            required
          />
        )}
      />
      <TextField
        label="City"
        value={data.location.city}
        onChange={(e) =>
          updateData({ location: { ...data.location, city: e.target.value } })
        }
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Additional Details"
        value={data.location.details}
        onChange={(e) =>
          updateData({
            location: { ...data.location, details: e.target.value }
          })
        }
        fullWidth
        margin="normal"
        helperText="
Add additional details to identify dispenser location easily e.g. street address, such as block, floor, room number, etc."
      />
    </Box>
  );
};

export default StepLocation;
