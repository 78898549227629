import React, { useMemo, useState } from "react";
import {
  SalesData,
  AccountType
} from "../../../../common/types/DistributorSalesTypes";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Close } from "@mui/icons-material";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogActions,
  DialogContent,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import StyledIconButton from "../../../../shared/Components/Styled/StyledIconButton";
import theme from "../../../../theme";
import { allMonths } from "../../../../common/types/constants";

type ProductSalesSummaryProps = {
  open: boolean;
  onClose: () => void;
  distributorFilter: string;
  sales: SalesData[];
  year: string;
};

const ProductSalesSummary: React.FC<ProductSalesSummaryProps> = ({
  open,
  onClose,
  distributorFilter,
  sales,
  year
}) => {
  const [monthFilter, setMonthFilter] = useState<string>("All");
  const [countryFilter, setCountryFilter] = useState<string>("All");
  const [quarterFilter, setQuarterFilter] = useState<string>("All");
  const [accountTypeFilter, setAccountTypeFilter] = useState<string>("All");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setMonthFilter("All");
    setCountryFilter("All");
    setQuarterFilter("All");
    setAccountTypeFilter("All");
    onClose();
  };

  const handleQuarterChange = (e: SelectChangeEvent) => {
    setQuarterFilter(e.target.value as string);
  };

  // Define DataGrid columns
  const columns: GridColDef[] = [
    { field: "product", headerName: "Product", minWidth: 150, flex: 1 },
    { field: "quantity", headerName: "Quantity Sold", minWidth: 150, flex: 1 }
  ];

  const productSummary = useMemo(() => {
    const productMap: Record<string, number> = {};

    sales
      .filter((sale) => {
        const saleDate = new Date(sale.date || "");
        const saleYear = saleDate.getFullYear().toString();
        const saleMonth = allMonths[saleDate.getMonth()]; // Convert month index to name

        // Apply year filter
        if (saleYear !== year) return false;

        // Apply distributor filter
        if (
          distributorFilter !== "All" &&
          sale.distributorEmail !== distributorFilter
        )
          return false;

        // Apply country filter
        if (
          countryFilter !== "All" &&
          sale.distributorCountry !== countryFilter
        )
          return false;

        // Apply account type filter
        if (
          accountTypeFilter !== "All" &&
          sale.account.accountType !== accountTypeFilter
        )
          return false;

        // Apply month filter
        if (monthFilter !== "All") {
          return saleMonth === monthFilter;
        }

        // Apply quarter filter if monthFilter is "All"
        if (quarterFilter !== "All") {
          const quarters: Record<string, string[]> = {
            Q1: ["January", "February", "March"],
            Q2: ["April", "May", "June"],
            Q3: ["July", "August", "September"],
            Q4: ["October", "November", "December"]
          };
          return quarters[quarterFilter].includes(saleMonth);
        }

        // If no specific filter matches, include the sale
        return true;
      })
      .forEach((sale) => {
        if (sale.product !== "NONE") {
          productMap[sale.product] = (productMap[sale.product] || 0) + 1;
        }
      });

    // Convert the productMap to an array for DataGrid
    return Object.entries(productMap).map(([product, quantity]) => ({
      id: product, // Use product type as unique identifier
      product,
      quantity
    }));
  }, [
    sales,
    year,
    monthFilter,
    distributorFilter,
    countryFilter,
    quarterFilter,
    accountTypeFilter
  ]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f5f5f5",
            py: 1
          }}
        >
          <DialogTitle
            fontSize={isMobile ? 14 : 16}
            sx={{
              backgroundColor: "#f5f5f5",
              fontWeight: "bold",
              pb: 2
            }}
          >
            {monthFilter === "All"
              ? `Sales Summary for ${year}`
              : `Sales Summary for ${monthFilter} ${year}`}
          </DialogTitle>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pr: 2
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: 480
              }}
            >
              <FormControl sx={{ minWidth: 160 }} size="small">
                <InputLabel>Account Type</InputLabel>
                <Select
                  label="Account Type"
                  value={accountTypeFilter}
                  onChange={(e) =>
                    setAccountTypeFilter(e.target.value as string)
                  }
                >
                  <MenuItem value="All">All</MenuItem>
                  {Object.values(AccountType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 65 }} size="small">
                <InputLabel>Quarter</InputLabel>
                <Select
                  label="Quarter"
                  value={quarterFilter}
                  onChange={handleQuarterChange}
                  disabled={monthFilter !== "All"}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Q1">Q1</MenuItem>
                  <MenuItem value="Q2">Q2</MenuItem>
                  <MenuItem value="Q3">Q3</MenuItem>
                  <MenuItem value="Q4">Q4</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel>Country</InputLabel>
                <Select
                  label="Country"
                  value={countryFilter}
                  onChange={(e) => setCountryFilter(e.target.value as string)}
                >
                  <MenuItem value="All">All</MenuItem>
                  {[
                    ...new Set(sales.map((sale) => sale.distributorCountry))
                  ].map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 100 }} size="small">
                <InputLabel>Select Month</InputLabel>
                <Select
                  label="Select Month"
                  value={monthFilter}
                  onChange={(e) => {
                    setMonthFilter(e.target.value as string);
                    if (e.target.value !== "All") setQuarterFilter("Q1");
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {allMonths.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <DialogActions>
              <StyledIconButton
                variantType="destructive"
                color="error"
                onClick={handleClose}
              >
                <Close />
              </StyledIconButton>
            </DialogActions>
          </Box>
        </Box>
        <DialogContent>
          <Box sx={{ p: 2, width: "96%", mt: 1 }}>
            <DataGrid
              rows={productSummary}
              columns={columns}
              getRowId={(row) => row.id} // Use product type as unique identifier
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                }
              }}
              pageSizeOptions={[5, 10, 25]}
              disableRowSelectionOnClick
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductSalesSummary;
