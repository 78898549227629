import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import type { InstallationData } from "../../../../common/types/InstallDeviceTypes";

interface StepSerialProps {
  data: InstallationData;
  updateData: (fields: Partial<InstallationData>) => void;
}

const StepSerial: React.FC<StepSerialProps> = ({ data, updateData }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold"
          }}
        >
          Serial Number
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary"
          }}
        >
          Please enter the serial number found on the bottom of your dispenser
          (Lotus Pro Dispenser)
        </Typography>
      </Box>
      <TextField
        label="Serial Number"
        value={data.serialNumber}
        onChange={(e) => updateData({ serialNumber: e.target.value })}
        fullWidth
        variant="outlined"
        required
      />
    </Box>
  );
};

export default StepSerial;
