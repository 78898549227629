import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { DeviceData } from "../../../common/types/DeviceTypes";
import UserCard from "./Cards/UserCard";
import SectionHeader from "./SectionHeader";
import SmallCard from "./Cards/SmallCard";

interface DeviceOverviewProps {
  device: DeviceData;
}

const DeviceOverview: React.FC<DeviceOverviewProps> = ({ device }) => {
  const {
    deviceInformation,
    memoryUsage,
    pingStatus,
    deviceType,
    deviceLocation
  } = device;

  const commonCardStyle = {
    p: 2,
    borderRadius: "8px",
    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
  };

  const renderDeviceInfo = (label: string, value: string | undefined) => (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1" sx={{}}>
        {value || "N/A"}
      </Typography>
    </Grid>
  );

  const deviceUsers = [
    { email: deviceInformation.customerEmail, role: "Customer" },
    { email: deviceInformation.installerEmail, role: "Installer" },
    ...deviceInformation.additionalCustomers.map((email) => ({
      email,
      role: "Additional Customer"
    })),
    ...deviceInformation.additionalUsers.map((email) => ({
      email,
      role: "Additional User"
    }))
  ].filter((user) => user.email); // Filter out any undefined or empty emails

  return (
    <Box sx={{ py: 2 }}>
      {/* First Row */}
      <Grid
        container
        spacing={1.5}
        sx={{ mb: 1, display: "flex", alignItems: "stretch" }}
      >
        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
          <Box sx={{ ...commonCardStyle, width: "100%" }}>
            <SectionHeader title="Device Information" />
            <Grid container spacing={1.5}>
              {renderDeviceInfo("Name", deviceInformation.deviceName)}
              {renderDeviceInfo("Model", deviceType)}
              {renderDeviceInfo("Serial", deviceInformation.serial)}
              {renderDeviceInfo(
                "Current Cartridge Type",
                deviceInformation.currentCartridgeType
              )}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
          <Box sx={{ ...commonCardStyle, width: "100%" }}>
            <SectionHeader title="Device Location" />
            <Grid container spacing={1.5}>
              {renderDeviceInfo("Country", deviceLocation?.country)}
              {renderDeviceInfo("State", deviceLocation?.state)}
              {renderDeviceInfo("City", deviceLocation?.city)}
              {renderDeviceInfo("Address", deviceLocation?.address)}
              {renderDeviceInfo("Postal Code", deviceLocation?.postalCode)}
              {renderDeviceInfo(
                "Latitude",
                deviceLocation?.latitude?.toString()
              )}
              {renderDeviceInfo(
                "Longitude",
                deviceLocation?.longitude?.toString()
              )}
              {renderDeviceInfo(
                "Additional Details",
                deviceLocation?.additionalDetails?.toString()
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Second Row: Device Users */}
      <Box sx={{ ...commonCardStyle, mb: 1 }}>
        <SectionHeader title="Device Users" />
        <Box sx={{ display: "flex", gap: 1.5, flexWrap: "wrap" }}>
          {deviceUsers.map((user) => (
            <UserCard
              key={user.email}
              firstName={user.role}
              lastName=""
              email={user.email}
            />
          ))}
        </Box>
      </Box>

      {/* Third Row: 4 columns layout */}
      <Grid
        container
        spacing={1.5}
        sx={{ display: "flex", alignItems: "stretch" }}
      >
        <SmallCard
          title="Device IoT Version"
          subtitle1="Version"
          subtitle1text={device.iotVersion?.version || "N/A"}
          subtitle2="Updated Date"
          subtitle2text={device.iotVersion?.updatedAt || "N/A"}
        />
        <SmallCard
          title="Device Life Cycle"
          subtitle1="Install Date"
          subtitle1text={new Date(device.createdAt!).toLocaleDateString()}
          subtitle2="Warranty"
          subtitle2text="Check"
        />
        <SmallCard
          title="Memory Status"
          subtitle1="Used"
          subtitle1text={`${memoryUsage.used} MB - Total: ${memoryUsage.total} MB`}
          subtitle2="Free"
          subtitle2text={`${memoryUsage.free} MB`}
        />
        <SmallCard
          title="Ping Status"
          subtitle1="Status"
          subtitle1text={pingStatus.status}
          subtitle2="Last Ping"
          subtitle2text={pingStatus.timestamp}
        />
      </Grid>
    </Box>
  );
};

export default DeviceOverview;
