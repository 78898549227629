import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Typography, TextField, Link } from "@mui/material";
import type { InstallationData } from "../../../../common/types/InstallDeviceTypes";
import { fetchCustomersForUser } from "../../../../services/customer.service";
import { Customer } from "../../../../store/useCustomer";
import useAuthStore from "../../../../store/authStore";

interface StepCustomerProps {
  data: InstallationData;
  updateData: (fields: Partial<InstallationData>) => void;
  onAddNewCustomer: () => void;
}

const StepCustomer: React.FC<StepCustomerProps> = ({
  data,
  updateData,
  onAddNewCustomer
}) => {
  const { userId } = useAuthStore();
  const [customerList, setCustomerList] = useState<Customer[]>([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (userId) {
        const customers = await fetchCustomersForUser();
        setCustomerList(customers);
      }
    };
    fetchCustomers();
  }, [userId]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold"
          }}
        >
          Customer Email
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary"
          }}
        >
          Please enter the email of the customer you're installing the device
          for
        </Typography>
      </Box>
      <Autocomplete
        options={customerList}
        value={
          customerList.find(
            (customer) => customer.email === data.customerEmail
          ) || null
        }
        renderOption={(props, option) => {
          const { key, ...otherProps } = props;
          return (
            <li key={option.email} {...otherProps}>
              {option.firstName} {option.lastName} - {option.email}
            </li>
          );
        }}
        getOptionLabel={(option) =>
          `${option.firstName} ${option.lastName} - ${option.email}`
        }
        onChange={(_event, selectedOption) => {
          updateData({ customerEmail: selectedOption?.email || "" });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Customer Email (Optional)"
            type="email"
            helperText="You can skip this step if no customer is selected"
          />
        )}
      />
      <Link
        component="button"
        variant="body2"
        onClick={(e) => {
          e.preventDefault();
          onAddNewCustomer();
        }}
        sx={{ alignSelf: "flex-start" }}
      >
        Add New Customer
      </Link>
    </Box>
  );
};

export default StepCustomer;
