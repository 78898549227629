import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Dismiss24Regular } from "@fluentui/react-icons";
import StepSerialReconnect from "../../Shared/DeviceInstallation/Steps/StepSerialReconnect";
import StepWifiSetupChoice from "../../Shared/DeviceInstallation/Steps/StepWifiSetupChoice";
import StepWifiSetup from "../../Shared/DeviceInstallation/Steps/StepWifiSetup";
import StepConnecting from "../../Shared/DeviceInstallation/Steps/StepConnecting";
import StepSuccess from "../../Shared/DeviceInstallation/Steps/StepSuccess";
import StepNavigation from "../../Shared/DeviceInstallation/StepNavigation";
import { fetchDeviceDetails } from "../../../services/device.service";
import type {
  ReconnectStep,
  ReconnectData
} from "../../../common/types/InstallDeviceTypes";
import { sendDeviceInstallationEmail } from "../../../services/device.service";
import useDeviceStore from "../../../store/useDeviceStore";

interface ReconnectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const INITIAL_DATA: ReconnectData = {
  serialNumber: ""
};

const ReconnectModal: React.FC<ReconnectModalProps> = ({ isOpen, onClose }) => {
  const MAX_RETRY_ATTEMPTS = 3;
  const { fetchDevices } = useDeviceStore();
  const [step, setStep] = useState<ReconnectStep>("serial");
  const [data, setData] = useState<ReconnectData>(INITIAL_DATA);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [pingAttempts, setPingAttempts] = useState(0);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [retryAttempts, setRetryAttempts] = useState(1);
  const [wifiSetupType, setWifiSetupType] = useState<"single" | "multiple">(
    "single"
  );

  const updateData = (fields: Partial<ReconnectData>) => {
    setData((prev) => ({ ...prev, ...fields }));
  };

  const goBack = () => {
    switch (step) {
      case "wifi-setup":
        setStep("wifi-setup-choice");
        break;
      default:
        break;
    }
  };

  const handleContinue = async () => {
    if (step === "wifi-setup") {
      setStep("connecting");
    } else if (step === "success") {
      onClose();
    } else {
      nextStep();
    }
  };

  const startConnectionCheck = () => {
    setPingAttempts(0);
    setError(null);
    checkDeviceConnection();
  };

  const handleRetryConnection = () => {
    if (retryAttempts >= MAX_RETRY_ATTEMPTS) {
      setError(
        "Maximum retry attempts reached. Please wait 30 minutes and try again later."
      );
      return;
    }
    setRetryAttempts((prev) => prev + 1);
    startConnectionCheck();
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (step === "connecting" && data.serialNumber) {
      // Set interval to check every 5 seconds, but stop after 12 attempts
      intervalId = setInterval(() => {
        if (pingAttempts >= 12) {
          clearInterval(intervalId);
          setError(
            "Device connection timeout after 12 attempts. Please ensure the device is properly connected to WiFi and try again."
          );
          return;
        }
        checkDeviceConnection();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [step, data.serialNumber, pingAttempts]);

  const checkDeviceConnection = async () => {
    if (!data.serialNumber) {
      console.log("No deviceId available for connection check");
      return;
    }

    setIsConnecting(true);
    setError(null);

    try {
      const deviceDetails = await fetchDeviceDetails(data.serialNumber);
      const isOnline = deviceDetails.pingStatus.status === "ONLINE";

      if (isOnline) {
        setIsConnecting(false);
        setIsEmailSent(false);
        fetchDevices();
        try {
          await sendDeviceInstallationEmail(
            deviceDetails.deviceInformation.customerEmail,
            deviceDetails.deviceInformation.deviceName
          );
          setIsEmailSent(true);
        } catch (emailErr) {
          console.error("Failed to send email:", emailErr);
          setIsEmailSent(true);
        }
        setStep("success");
      } else {
        setPingAttempts((prev) => {
          const newAttempts = prev + 1;
          if (newAttempts >= 12) {
            setError("Unable to connect to device. Please try again.");
            setIsConnecting(false);
          }
          return newAttempts;
        });
      }
    } catch (err: any) {
      console.error("Connection check error:", err);
      setError(err.message || "Failed to connect to device");
    }
  };

  const nextStep = () => {
    switch (step) {
      case "serial":
        setStep("wifi-setup-choice");
        break;
      default:
        break;
    }
  };

  const canContinue = () => {
    switch (step) {
      case "serial":
        return data.serialNumber !== "";
      case "wifi-setup-choice":
      case "wifi-setup":
      case "success":
        return true;
      default:
        return false;
    }
  };

  const handleSetupTypeSelect = (type: "single" | "multiple") => {
    setWifiSetupType(type);
    setStep("wifi-setup");
  };

  const renderStep = () => {
    switch (step) {
      case "serial":
        return <StepSerialReconnect data={data} updateData={updateData} />;
      case "wifi-setup-choice":
        return (
          <StepWifiSetupChoice onSelectSetupType={handleSetupTypeSelect} />
        );
      case "wifi-setup":
        return (
          <StepWifiSetup
            serialNumber={data.serialNumber}
            deviceSetup={wifiSetupType}
          />
        );
      case "connecting":
        return (
          <StepConnecting
            isConnecting={isConnecting}
            error={error}
            pingAttempts={pingAttempts}
            onRetry={handleRetryConnection}
            retryAttempts={retryAttempts}
            maxRetryAttempts={MAX_RETRY_ATTEMPTS}
          />
        );
      case "success":
        return <StepSuccess isEmailSent={isEmailSent} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.85)"
          }
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
        >
          <Dismiss24Regular />
        </IconButton>
      </DialogTitle>
      <DialogContent>{renderStep()}</DialogContent>
      <StepNavigation
        step={step}
        isLoading={isConnecting}
        onBack={goBack}
        onContinue={handleContinue}
        canContinue={canContinue()}
        isEmailSent={isEmailSent}
        error={error}
      />
    </Dialog>
  );
};

export default ReconnectModal;
