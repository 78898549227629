import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import StyledTabs from "../../shared/Components/Styled/StyledTabs";
import StyledTab from "../../shared/Components/Styled/StyledTab";
import DistributorTable from "./Distributors/ManageDistributorTable";
import SubDistributorTable from "./SubDistributors/ManageSubDistributorTable";
import ManageInstallers from "./Installers/ManageInstallerTable";
import CustomerTable from "./Customers/ManageCustomerTable";
import ManageGroupCustomers from "./GroupCustomers/ManageGroupCustomers";
import useAuthStore from "../../store/authStore";

const ManageUsers: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { userRole } = useAuthStore.getState() || {};

  // Tab configuration with roles and corresponding components
  const TabConfigurations = [
    {
      roles: ["SuperAdmin", "Admin", "SubDistributor"],
      label: "Distributors",
      component: <DistributorTable />
    },
    {
      roles: ["SuperAdmin", "Admin", "Distributor"],
      label: "Sub-Distributors",
      component: <SubDistributorTable />
    },
    {
      roles: ["SuperAdmin", "Admin", "Distributor", "SubDistributor"],
      label: "Installers",
      component: <ManageInstallers />
    },
    {
      roles: ["SuperAdmin", "Admin", "Distributor", "SubDistributor"],
      label: "Customers",
      component: <CustomerTable onCustomerSelect={() => {}} />
    },
    {
      roles: ["SuperAdmin", "Admin", "Distributor", "SubDistributor"],
      label: "Group Customers",
      component: <ManageGroupCustomers />
    }
    // {
    //   roles: ["SuperAdmin", "Admin"],
    //   label: "Testers",
    //   component: <div>Testers Component</div>, // Placeholder for testers
    // },
  ];

  // Filter tabs based on user role
  const filteredTabs = TabConfigurations.filter((tab) =>
    userRole ? tab.roles.includes(userRole) : false
  );

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "95%", md: "96%" }
        }}
      >
        <Box
          sx={{
            backgroundColor: "transparent",
            borderRadius: 1,
            border: "1px solid grey.800"
          }}
        >
          <Typography sx={{ mb: 2, textAlign: { xs: "left" } }} variant="h6">
            Manage Users
          </Typography>
        </Box>
        <StyledTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="User tabs"
        >
          {filteredTabs.map((tab, index) => (
            <StyledTab key={index} label={tab.label} sx={{ mx: 0.2 }} />
          ))}
        </StyledTabs>

        {/* Render the selected tab's component */}
        {filteredTabs[selectedTab]?.component}
      </Box>
    </Box>
  );
};

export default ManageUsers;
