import { Alert, Tooltip, useTheme } from "@mui/material";
import { PresenceBlocked24Regular } from "@fluentui/react-icons";

const DeviceSuspendedAlert: React.FC = () => {
  const theme = useTheme();

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#FDEDED",
            border: "1px solid #e0e0e0"
          }
        }
      }}
      title={
        <Alert severity="error">
          Warning: DEVICE SUSPENDED. Device has been suspended due to unusual
          activity detected in usage recordings. It will be unsuspended once the
          issue is resolved.
        </Alert>
      }
    >
      <PresenceBlocked24Regular primaryFill={theme.palette.error.dark} />
    </Tooltip>
  );
};

export default DeviceSuspendedAlert;
