import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";
import type { InstallationData } from "../../../../common/types/InstallDeviceTypes";
import { addCustomer } from "../../../../services/customer.service";
import useAuthStore from "../../../../store/authStore";

interface StepAddCustomerProps {
  data: InstallationData;
  updateData: (fields: Partial<InstallationData>) => void;
  onSuccess: () => void;
}

const StepAddCustomer: React.FC<StepAddCustomerProps> = ({
  updateData,
  onSuccess
}) => {
  const { user } = useAuthStore();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });
  const [error, setError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      await addCustomer({
        ...formData,
        role: "Customer",
        distributorEmail: user?.email || ""
      });
      updateData({ customerEmail: formData.email });
      onSuccess();
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to add customer");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Add New Customer
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Please enter the customer's details
        </Typography>
      </Box>
      <Box>
        <TextField
          name="firstName"
          label="First Name"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          required
          margin="normal"
        />
        <TextField
          name="lastName"
          label="Last Name"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          required
          margin="normal"
        />
        <TextField
          name="email"
          label="Email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          required
          margin="normal"
        />
      </Box>
      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
      <StyledButton type="submit" variantType="primary" disabled={isSubmitting}>
        {isSubmitting ? "Adding..." : "Add Customer"}
      </StyledButton>
    </Box>
  );
};

export default StepAddCustomer;
