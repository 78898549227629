import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import StyledTabs from "../../../shared/Components/Styled/StyledTabs";
import StyledTab from "../../../shared/Components/Styled/StyledTab";

interface HeaderProps {
  selectedTab: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const Header: React.FC<HeaderProps> = ({ selectedTab, handleTabChange }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        {/* First Row - Header */}
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Tersano Portal & Docs</Typography>
          <StyledButton
            variantType="outline-primary"
            customFontColor="white"
            customHoverColor="#669ac9"
            onClick={() => navigate("/")}
          >
            Login
          </StyledButton>
        </Box>

        {/* Second Row - Tabs */}
        <Box
          sx={{
            backgroundColor: "white",
            px: 2,
            py: 1
          }}
        >
          <StyledTabs value={selectedTab} onChange={handleTabChange}>
            <StyledTab label="Getting Started" value="getting-started" />
            <StyledTab label="Distributor Portal" value="distributor-portal" />
            <StyledTab label="Installer Portal" value="installer-portal" />
            <StyledTab label="Customer Portal" value="customer-portal" />
            <StyledTab label="Company Portal" value="company-portal" />
            <StyledTab
              label="Customer Integration"
              value="customer-integration"
            />
            <StyledTab label="Training Videos" value="training-videos" />
          </StyledTabs>
        </Box>
      </Box>
    </>
  );
};

export default Header;
