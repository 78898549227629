import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import type { InstallationData } from "../../../../common/types/InstallDeviceTypes";

interface StepNameProps {
  data: InstallationData;
  updateData: (fields: Partial<InstallationData>) => void;
}

const StepName: React.FC<StepNameProps> = ({ data, updateData }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold"
          }}
        >
          Device Name
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary"
          }}
        >
          Enter a descriptive name for your device. This will help you recognize
          it on the dashboard
        </Typography>
      </Box>
      <TextField
        label="Device Name"
        value={data.deviceName}
        onChange={(e) => updateData({ deviceName: e.target.value })}
        fullWidth
        variant="outlined"
        required
      />
    </Box>
  );
};

export default StepName;
