import React, { useState } from "react";
import { Box, Typography, Snackbar, Alert, AlertColor } from "@mui/material";
import UserSupport from "./UserSupport";
import { DeviceData } from "../../common/types/DeviceTypes";
import ChangeRequestsTable from "./ChangeRequestsTable";
import StyledTab from "../../shared/Components/Styled/StyledTab";
import StyledTabs from "../../shared/Components/Styled/StyledTabs";
import useAuthStore from "../../store/authStore";

interface HelpDeskProps {
  devices: DeviceData[];
}

export const HelpDesk: React.FC<HelpDeskProps> = ({ devices }) => {
  const { userRole } = useAuthStore();
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedHelpDeskTab", newValue.toString());
  };

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Help Desk
            </Typography>
          </Box>

          <StyledTabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="Profile tabs"
          >
            <StyledTab label="Overview" />
            {userRole === "Distributor" && (
              <StyledTab label="Change requests" />
            )}
          </StyledTabs>
          <Box sx={{ p: 2 }}>
            {selectedTab === 0 && <UserSupport devices={devices} />}
            {selectedTab === 1 && <ChangeRequestsTable />}
          </Box>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};
