import React, { useState } from "react";
import { Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { Wifi } from "@mui/icons-material";
import type { DeviceSetup } from "../../../../common/types/InstallDeviceTypes";

interface WifiSetupProps {
  serialNumber: string;
  deviceSetup: DeviceSetup;
}

const SETUP_INSTRUCTIONS = {
  single: [
    {
      id: "1",
      title: "Disconnect from current WiFi",
      description:
        "Open your device settings and disconnect from your current WiFi network"
    },
    {
      id: "2",
      title: "Connect to Lotus Pro",
      description:
        "Find and connect to LotusPro-(SerialNumber) using password: 123456789"
    },
    {
      id: "3",
      title: "Open Setup Page",
      description: "Open a browser and navigate to tersano.local or 192.168.4.1"
    },
    {
      id: "4",
      title: "Configure Device WiFi",
      description: "Enter your WiFi network details on the setup page"
    },
    {
      id: "5",
      title: "Reconnect to Your WiFi",
      description:
        "Go back to your device settings and reconnect to your original WiFi network"
    }
  ],
  multiple: [
    {
      id: "1",
      title: "Keep Portal Open",
      description: "Keep this portal open on your primary device (laptop)"
    },
    {
      id: "2",
      title: "Connect to Device",
      description:
        "On your secondary device (phone/tablet), go to WiFi settings, find and connect to LotusPro-(SerialNumber) using the password: 123456789"
    },
    {
      id: "3",
      title: "Open Setup Page",
      description:
        "On your secondary device, open a browser and navigate to tersano.local or 192.168.4.1"
    },
    {
      id: "4",
      title: "Configure WiFi",
      description: "Enter your WiFi network details on the setup page"
    },
    {
      id: "5",
      title: "Return to Portal",
      description: "Return to this portal to complete the setup"
    }
  ]
};

const StepWifiSetup: React.FC<WifiSetupProps> = ({
  serialNumber,
  deviceSetup
}) => {
  const [completedSteps, setCompletedSteps] = useState<Set<string>>(new Set());
  const setupInstructions = deviceSetup ? SETUP_INSTRUCTIONS[deviceSetup] : [];

  const toggleStep = (stepId: string) => {
    setCompletedSteps((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(stepId)) {
        newSet.delete(stepId);
      } else {
        newSet.add(stepId);
      }
      return newSet;
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 1
          }}
        >
          <Wifi sx={{ fontSize: 24 }} /> WiFi Setup Instructions
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Follow these steps to connect your Lotus Pro device to WiFi
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {setupInstructions.map((step, index) => (
          <Box
            key={step.id}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              p: 2,
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 1,
              bgcolor: completedSteps.has(step.id)
                ? "rgba(0, 200, 0, 0.05)"
                : "transparent"
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={completedSteps.has(step.id)}
                  onChange={() => toggleStep(step.id)}
                />
              }
              label={
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    Step {index + 1}: {step.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {step.description.replace("(SerialNumber)", serialNumber)}
                  </Typography>
                </Box>
              }
              sx={{ m: 0, alignItems: "flex-start" }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default StepWifiSetup;
