import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Modal,
  Stepper,
  Step,
  StepLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Snackbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useForm, Controller } from "react-hook-form";
import { createCustomerProductWarranty } from "../../services/product-warranty.service";
import { CreateCustomerProductWarranty } from "../../common/types/ProductWarrantyTypes";
import StyledButton from "../../shared/Components/Styled/StyledButton";

const steps = [
  "Distributor Information",
  "Product & Site Information",
  "Fault Description",
  "Summary"
];

interface EditWarrantyModalProps {
  open: boolean;
  onClose: () => void;
}

const CustomerWarrantyModal: React.FC<EditWarrantyModalProps> = ({
  open,
  onClose
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success"
  });
  const { handleSubmit, control, reset, getValues } = useForm();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = async () => {
    const data = getValues();

    const newWarranty: CreateCustomerProductWarranty = {
      distributorInformation: {
        companyName: data.companyName,
        accountNumber: data.accountNumber,
        email: data.email,
        phone: data.phone,
        address: data.address,
        city: data.city,
        country: data.country,
        region: data.region,
        zip: data.zip,
        submittedByDistributor: true
      },
      productSiteInformation: {
        serialNumber: data.serialNumber,
        deviceModel: data.deviceModel,
        purchaseDate: data.purchaseDate,
        company: data.company,
        address: data.address,
        city: data.city,
        country: data.country,
        region: data.region,
        zip: data.zip
      },
      faultDescription: {
        returnReason: data.returnReason,
        defectType: data.defectType,
        description: data.description,
        photo: data.photo,
        warrantyType: data.warrantyType || "OUT",
        creditStatus: data.creditStatus || "NO",
        creditAmount: data.creditAmount || 0,
        replacementUnit: data.replacementUnit || "NO",
        errorCode: data.errorCode || []
      },
      status: "Initial review"
    };

    try {
      await createCustomerProductWarranty(newWarranty);
      setSnackbar({
        open: true,
        message: "Warranty created successfully!",
        severity: "success"
      });
      onClose();
      reset();
      setActiveStep(0);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to create warranty.",
        severity: "error"
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "70%", md: "40%" },
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "#f5f5f5",
            p: { xs: 2, sm: 3, md: 3 },

            borderRadius: 1
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                color: "grey.800",
                textAlign: "center"
              }}
            >
              Warranty Details
            </Typography>
            <IconButton onClick={onClose} color="inherit">
              <CloseIcon sx={{ color: "error.main" }} />
            </IconButton>
          </Box>

          {/* Warranty Details Form */}
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <form
            onSubmit={handleSubmit(
              activeStep === steps.length - 1 ? handleFinish : handleNext
            )}
          >
            {activeStep === 0 && (
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="companyName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Company or Name (Last, First) "
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="accountNumber"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Account Number"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {" "}
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Email"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Phone Number (With Area Code)"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {" "}
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Street Address"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="city"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="City / Town"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Country"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="region"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Province / State"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {" "}
                    <Controller
                      name="zip"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Zip / Postal Code"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            {activeStep === 1 && (
              <Box sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="deviceModel"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        select
                        required
                        label="Model / Series"
                        {...field}
                        sx={{ mt: 2 }}
                        SelectProps={{ native: true }}
                      >
                        <option value="" />
                        <option value="Lotus PRO SAO Dispenser (LQFC225K)">
                          Lotus PRO SAO Dispenser (LQFC225K)
                        </option>
                        <option value="Lotus PRO SAO Dispenser (LQFC225K-FB)">
                          Lotus PRO SAO Dispenser (LQFC225K-FB)
                        </option>
                        <option value="Lotus PRO SAO Dispenser (LQFC275-FB)">
                          Lotus PRO SAO Dispenser (LQFC275-FB)
                        </option>
                        <option value="Lotus PRO SAO Dispenser (LQFC275)">
                          Lotus PRO SAO Dispenser (LQFC275)
                        </option>
                        <option value="iClean Mini (LQFC200)">
                          iClean Mini (LQFC200)
                        </option>
                        <option value="iClean PRO (LQFC300)">
                          iClean PRO (LQFC300)
                        </option>
                        <option value="SAO2GO (LQFC400)">
                          SAO2GO (LQFC400)
                        </option>
                        <option value="SAO2GO (LQFC401)">
                          SAO2GO (LQFC401)
                        </option>
                        <option value="iClean Flo (LQFC5XX)">
                          iClean Flo (LQFC5XX)
                        </option>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="serialNumber"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Serial No."
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="purchaseDate"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Original Date of Purchase"
                          type="date"
                          {...field}
                          sx={{ mt: 2 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="company"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Company or Name (Last, First)"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Street Address"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="city"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="City / Town"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Country"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="region"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Province / State"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="zip"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Zip / Postal Code"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            {activeStep === 2 && (
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="returnReason"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          select
                          label="Return Reason"
                          {...field}
                          sx={{ mt: 2 }}
                          SelectProps={{ native: true }}
                        >
                          <option value="" />
                          <option value="Customer Return">
                            Customer Return
                          </option>
                          <option value="Defective">Defective</option>
                          <option value="Received Wrong Item">
                            Received Wrong Item
                          </option>
                          <option value="Damaged Shipment">
                            Damaged Shipment
                          </option>
                          <option value="Lost Shipment">Lost Shipment</option>
                          <option value="Missing">Missing Item</option>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Description"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        border: "2px dashed grey",
                        borderRadius: 1,
                        textAlign: "center",
                        p: 2,
                        mt: 2
                      }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Drag and drop images to upload...
                      </Typography>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input hidden accept="image/*" type="file" />
                        <CloudUploadIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {activeStep === 3 && (
              <Box sx={{ mt: 2 }}>
                <Accordion defaultExpanded sx={{ mt: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="distributor-content"
                    id="distributor-header"
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      Distributor Information
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Company Name:</strong> {getValues().companyName}
                      <br />
                      <strong>Account Number:</strong>{" "}
                      {getValues().accountNumber}
                      <br />
                      <strong>Email:</strong> {getValues().email}
                      <br />
                      <strong>Phone:</strong> {getValues().phone}
                      <br />
                      <strong>Address:</strong> {getValues().address}
                      <br />
                      <strong>City:</strong> {getValues().city}
                      <br />
                      <strong>Country:</strong> {getValues().country}
                      <br />
                      <strong>Region:</strong> {getValues().region}
                      <br />
                      <strong>Zip:</strong> {getValues().zip}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded sx={{ mt: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="product-site-content"
                    id="product-site-header"
                  >
                    <Typography>Product Site Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Serial Number:</strong> {getValues().serialNumber}
                      <br />
                      <strong>Device Model:</strong> {getValues().deviceModel}
                      <br />
                      <strong>Purchase Date:</strong> {getValues().purchaseDate}
                      <br />
                      <strong>Company:</strong> {getValues().company}
                      <br />
                      <strong>Address:</strong> {getValues().address}
                      <br />
                      <strong>City:</strong> {getValues().city}
                      <br />
                      <strong>Country:</strong> {getValues().country}
                      <br />
                      <strong>Region:</strong> {getValues().region}
                      <br />
                      <strong>Zip:</strong> {getValues().zip}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded sx={{ mt: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="fault-description-content"
                    id="fault-description-header"
                  >
                    <Typography>Fault Description</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Return Reason:</strong> {getValues().returnReason}
                      <br />
                      <strong>Description:</strong> {getValues().description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 1,
                mt: 2
              }}
            >
              {activeStep !== 0 && (
                <StyledButton onClick={handleBack}>Back</StyledButton>
              )}
              <StyledButton type="submit" variantType="primary">
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </StyledButton>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* Snackbar for showing success or error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomerWarrantyModal;
