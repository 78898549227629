export const gridSpacing = 3;
export const drawerWidth = 270;
export const miniDrawerWidth = 58;
export const iCleanCartridgeLife = 920;
export const offlineThresholdDays = 30;

export const alertRoles = ["SuperAdmin", "Admin", "Distributor"];

export type SelectedPortalType = "Lotus-Pro" | "iCleanLines";

export const countryLabels = [
  { label: "Canada" },
  { label: "United States" },

  { label: "Argentina" },
  { label: "Austria" },
  { label: "Australia" },
  { label: "Azerbaijan" },
  { label: "Belgium" },
  { label: "Bulgaria" },
  { label: "Brazil" },
  { label: "Bahamas" },
  { label: "Belarus" },
  { label: "Switzerland" },
  { label: "Chile" },
  { label: "China" },
  { label: "Colombia" },
  { label: "Costa Rica" },
  { label: "Cuba" },
  { label: "Cyprus" },
  { label: "Czech Republic" },
  { label: "Germany" },
  { label: "Denmark" },
  { label: "Estonia" },
  { label: "Egypt" },
  { label: "Finland" },
  { label: "Fiji" },
  { label: "France" },
  { label: "Georgia" },
  { label: "Greenland" },
  { label: "Greece" },
  { label: "Hong Kong" },
  { label: "Croatia" },
  { label: "Hungary" },
  { label: "Indonesia" },
  { label: "Ireland" },
  { label: "Israel" },
  { label: "India" },
  { label: "Iceland" },
  { label: "Italy" },

  { label: "Japan" },
  { label: "Korea, Democratic People's Republic of" },
  { label: "Kuwait" },
  { label: "Lithuania" },
  { label: "Luxembourg" },
  { label: "Morocco" },
  { label: "Monaco" },
  { label: "Mexico" },
  { label: "Malaysia" },
  { label: "Netherlands" },
  { label: "Norway" },
  { label: "New Zealand" },
  { label: "Oman" },
  { label: "Panama" },
  { label: "Philippines" },
  { label: "Poland" },
  { label: "Puerto Rico" },
  { label: "Portugal" },
  { label: "Paraguay" },
  { label: "Qatar" },
  { label: "Romania" },
  { label: "Serbia" },
  { label: "Saudi Arabia" },
  { label: "Spain" },
  { label: "Sweden" },
  { label: "Singapore" },
  { label: "Slovenia" },
  { label: "Slovakia" },
  { label: "Thailand" },
  { label: "Turkey" },
  { label: "Taiwan" },
  { label: "Ukraine" },
  { label: "United Arab Emirates" },
  { label: "United Kingdom" },
  { label: "Uruguay" },
  { label: "Venezuela" },
  { label: "South Africa" }
];

export const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const allDistributorEmails = [
  "ilija@ecotip.com.mk",
  "nathan@ecofy.net.au",
  "matias.infante@oxxontech.com",
  "yolanda.y@vicpuregroup.com",
  "rait.keerles@ecocare.ee",
  "ville.kujala@orbotech.fi",
  "d.machefert@eco-life-group.com",
  "ggruhler@kenter.de",
  "adrian@kouyoufas.gr",
  "nagy.amadea@smartclean.hu",
  "lakdawalarohan97@gmail.com",
  "genesis@yape.my",
  "yaki@clean-bit.com",
  "info@calitalia.com",
  "abdallah@ecotek.org",
  "tersano@wecovi.com",
  "MH@boss-europe.no",
  "thomas@cleanzone.no",
  "info@lgatkimson.com",
  "office@olpe.pl",
  "joao.carvalho@iteamportugal.pt",
  "paul@promedusa.us",
  "info@ecodistributionafrica.co.za",
  "guillem@jusmer.com",
  "anton.johansson@orbotech.se",
  "cl@hypros.com",
  "S.VanToorn@remaprodukte.ch",
  "amy@prozon.biz",
  "nehir@ermop.com",
  "siegfried@eslimited.ae",
  "benjamin.wright@ferguson.com",
  "viet@3ntechvietnam.com",
  "raybonilla@prodigy.net.mx",
  "ronan.mcdonnell@clenli.ie",
  "frank.cullen@clenli.ie"
];
