import { Box, Typography, Link } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

type HelperProps = {
  title: string;
  description: string;
  link: string;
};

const Helper: React.FC<HelperProps> = ({ title, description, link }) => {
  return (
    <Box>
      <Typography sx={{ mt: 5 }} variant="h6">
        {title}
        <HelpOutline sx={{ ml: 1, verticalAlign: "middle" }} />
      </Typography>

      <Typography variant="body2" sx={{ mt: 1 }}>
        {description}
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ ml: 1 }}
        >
          Click here
        </Link>
      </Typography>
    </Box>
  );
};

export default Helper;
