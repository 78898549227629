import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  AlertColor
} from "@mui/material";
import StyledTooltip from "../../../shared/Components/Styled/StyledTooltip";
import { Error as ErrorIcon } from "@mui/icons-material";
import {
  SalesData,
  AccountsData
} from "../../../common/types/DistributorSalesTypes";
import SalesInputForm from "./Modal/SalesInputForm";
import UpdateSalesModal from "./Modal/UpdateSalesModal";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import { allMonths } from "../../../common/types/constants";

interface MonthData {
  month: string;
  status: string;
  sales?: number;
}

interface MonthGridProps {
  year: string;
  distributor: string;
  sales: SalesData[];
  accounts: AccountsData[]; // Accounts list from the parent
  distributorEmail: string;
  setSnackbar: (snackbar: {
    open: boolean;
    message: string;
    severity: AlertColor;
  }) => void;
  refetchSales: () => void;
}

export default function MonthGrid({
  year,
  distributor,
  sales,
  accounts,
  distributorEmail,
  setSnackbar,
  refetchSales
}: MonthGridProps) {
  const [months, setMonths] = useState<MonthData[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [monthSales, setMonthSales] = useState<SalesData[]>([]);

  useEffect(() => {
    const filteredSales = sales.filter(
      (s) =>
        new Date(s.date || "").getFullYear().toString() === year &&
        (distributor === "All" || s.distributorEmail === distributor) // Allow all distributors if distributor is empty
    );

    const monthMap: Record<string, { count: number; reported: boolean }> = {};

    filteredSales.forEach((sale) => {
      const monthName = new Date(sale.date || "").toLocaleString("default", {
        month: "long"
      });
      if (!monthMap[monthName]) {
        monthMap[monthName] = { count: 0, reported: false };
      }
      // Exclude product: NONE from sales count but mark as reported
      if (sale.product !== "NONE") {
        monthMap[monthName].count += 1;
      }

      monthMap[monthName].reported = true; // Mark as reported regardless of product type
    });

    setMonths(
      allMonths.map((month) => ({
        month,
        status: monthMap[month]?.reported ? "Reported" : "Not Reported",
        sales: monthMap[month]?.count || 0
      }))
    );
  }, [year, distributor, sales]);

  // Dynamically filter the sales for the selected month
  useEffect(() => {
    if (selectedMonth) {
      const filteredMonthSales = sales.filter(
        (sale) =>
          new Date(sale.date || "").toLocaleString("default", {
            month: "long"
          }) === selectedMonth
      );
      setMonthSales(filteredMonthSales);
    }
  }, [selectedMonth, sales]);

  const handleReportSales = (month: string) => {
    setSelectedMonth(month);
    setOpenModal(true);
  };

  const handleUpdateSales = (month: string) => {
    // Find the sales data for the selected month
    // const monthSales = sales.filter(
    //   (sale) =>
    //     new Date(sale.date || "").toLocaleString("default", {
    //       month: "long"
    //     }) === month
    // );

    // Open the modal with the sales data
    setSelectedMonth(month);
    setOpenUpdateModal(true);
  };

  const isPastMonth = (month: string) => {
    const currentDate = new Date();
    const currentYear = Number.parseInt(year);
    const monthIndex = allMonths.indexOf(month);
    const monthDate = new Date(currentYear, monthIndex);
    return monthDate < currentDate;
  };

  return (
    <Grid container spacing={3} mb={4}>
      {months.map(({ month, status, sales }) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={month}>
          <StyledTooltip
            variant="error"
            title={
              status !== "Reported" && isPastMonth(month)
                ? "Deadline Missed! Please report sales for this month"
                : ""
            }
          >
            <Card
              onClick={() => {
                if (status === "Reported") {
                  handleUpdateSales(month);
                } else {
                  handleReportSales(month);
                }
              }}
              variant="outlined"
              sx={{
                position: "relative",
                overflow: "visible",
                borderRadius: 2,
                height: "100px",
                width: "100%",
                cursor: "pointer",
                transition: "all 0.3s",
                backgroundColor:
                  status === "Reported"
                    ? "rgba(144, 238, 144, 0.2)"
                    : " rgba(30, 58, 138, 0.1)",
                border:
                  status === "Reported"
                    ? "1px solid #707070 "
                    : "1px solid rgba(30, 58, 138, 1)",
                "&:hover": {
                  boxShadow: 1,
                  backgroundColor:
                    status === "Reported"
                      ? "rgba(144, 238, 144, 0.5)"
                      : "rgba(30, 58, 138, 0.2)",
                  transform: "scale(1.02)"
                }
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                  gap={1}
                >
                  <Typography
                    sx={{ marginTop: 0.2, fontSize: "16px", fontWeight: 700 }}
                    color={status === "Reported" ? "text.secondary" : "primary"}
                  >
                    {month}
                  </Typography>
                  <StyledButton
                    variantType="primary"
                    color={status === "Reported" ? "inherit" : "primary"}
                    // conditionally render onclick function based on status
                    onClick={
                      status === "Reported"
                        ? () => handleUpdateSales(month)
                        : () => handleReportSales(month)
                    }
                    sx={{
                      backgroundColor:
                        status === "Reported" ? "#DDE2E5" : "primary.light",
                      color: status === "Reported" ? "#495057" : "white",
                      "&:hover": {
                        backgroundColor:
                          status === "Reported" ? "grey.200" : "primary.dark"
                      },
                      borderRadius: 1,
                      px: 0.5,
                      py: 0.2,
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "11px",
                      fontWeight: 600,
                      height: "24px"
                    }}
                  >
                    {status === "Reported" ? "Update" : "Report"}
                  </StyledButton>
                </Box>
                <Typography
                  variant="body2"
                  color={status === "Reported" ? "text.secondary" : "primary"}
                  fontWeight={status === "Reported" ? "normal" : "bold"}
                  sx={{ fontSize: "12px" }}
                >
                  {status}
                </Typography>
                {status === "Reported" && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight="medium"
                    sx={{ fontSize: "12px" }}
                  >
                    {sales === 0 ? "No sales" : `Sales: ${sales}`}
                  </Typography>
                )}
                {status !== "Reported" && isPastMonth(month) && (
                  <ErrorIcon
                    color="warning"
                    sx={{
                      position: "absolute",
                      top: -5,
                      right: -5,
                      zIndex: 100
                    }}
                  />
                )}
              </CardContent>
            </Card>
          </StyledTooltip>
        </Grid>
      ))}
      {selectedMonth && (
        <SalesInputForm
          open={openModal}
          onClose={() => {
            setSelectedMonth(null);
            setOpenModal(false);
          }}
          month={selectedMonth}
          year={year}
          onSuccess={() => {
            setSnackbar({
              open: true,
              message: "Sales reported successfully",
              severity: "success"
            });
            setSelectedMonth(null);
            setOpenModal(false);
            refetchSales();
          }}
          onError={(message) => {
            setSnackbar({
              open: true,
              message: message,
              severity: "error"
            });
          }}
          accounts={accounts}
          distributorEmail={distributorEmail}
        />
      )}
      {selectedMonth && (
        <UpdateSalesModal
          month={selectedMonth}
          year={year}
          sales={monthSales}
          open={openUpdateModal}
          onClose={() => {
            setSelectedMonth(null);
            setOpenUpdateModal(false);
          }}
          accounts={accounts}
          distributorEmail={distributorEmail}
          distributorFilter={distributor}
          setSnackbar={setSnackbar}
          onDeleteSuccess={refetchSales}
          onEditSuccess={refetchSales}
          onAddSales={refetchSales}
        />
      )}
    </Grid>
  );
}
