import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  useTheme
} from "@mui/material";
import { ICleanDevice } from "../../../../common/types/DeviceTypes";
import { Badge } from "@fluentui/react-components";
import { getCartridgeLifeRemaining } from "../../../../utils/helper";
import LowCartridgeAlert from "../../../Shared/LowCartridgeAlert";
import {
  offlineThresholdDays,
  alertRoles
} from "../../../../common/types/constants";
import DeviceOfflineAlert from "../../../Shared/DeviceOfflineAlert";
import DeviceSuspendedAlert from "../../../Shared/DeviceSuspendedAlert";
import useAuthStore from "../../../../store/authStore";

interface DeviceCardsProps {
  devices: ICleanDevice[];
  onRowClick: (device: ICleanDevice) => void;
}

const DeviceCards: React.FC<DeviceCardsProps> = ({ devices, onRowClick }) => {
  const { userRole } = useAuthStore();
  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={3}>
        {devices.map((device) => {
          const cyclesRemaining = getCartridgeLifeRemaining(device);
          const isDeviceCartridgeLow = cyclesRemaining <= 30;
          const isDeviceSuspended = device.deviceInformation.isSuspended;

          const thresholdDate = new Date();
          thresholdDate.setDate(thresholdDate.getDate() - offlineThresholdDays);
          const isOffline =
            new Date(device.pingStatus.timestamp) < thresholdDate;

          return (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              key={device.deviceInformation.deviceId}
            >
              <Card
                onClick={() => onRowClick(device)}
                sx={{
                  borderRadius: 2,
                  boxShadow: 0,
                  border: "1px solid #bdbdbd",
                  cursor: "pointer",
                  height: "auto",
                  width: "100%",
                  backgroundColor:
                    isDeviceSuspended &&
                    userRole &&
                    alertRoles.includes(userRole)
                      ? "rgba(138, 30, 30, 0.09)"
                      : "rgba(30, 58, 138, 0.08)",
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  "&:hover": {
                    backgroundColor: "#ededed"
                  }
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    p: 2
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600 }}
                        >
                          {device.deviceInformation.deviceName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Serial: {device.deviceInformation.deviceId}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {isDeviceSuspended &&
                      userRole &&
                      alertRoles.includes(userRole) ? (
                        <DeviceSuspendedAlert />
                      ) : (
                        <>
                          {isOffline &&
                            userRole &&
                            alertRoles.includes(userRole) && (
                              <DeviceOfflineAlert
                                timestamp={device.pingStatus.timestamp}
                              />
                            )}
                          {isDeviceCartridgeLow &&
                            device.pingStatus.status !== "UNKNOWN" &&
                            userRole &&
                            alertRoles.includes(userRole) && (
                              <LowCartridgeAlert
                                cyclesRemaining={cyclesRemaining}
                              />
                            )}
                        </>
                      )}
                    </Box>
                  </Box>
                  {/* Badge */}
                  <Box>
                    <Badge
                      color={
                        device.pingStatus.status === "ONLINE"
                          ? "success"
                          : device.pingStatus.status === "OFFLINE"
                            ? "danger"
                            : "informative"
                      }
                    >
                      {device.pingStatus.status.charAt(0).toUpperCase() +
                        device.pingStatus.status.slice(1).toLowerCase()}
                    </Badge>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DeviceCards;
