import React, { useRef, useState, useEffect } from "react";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import { Paper, Typography, Divider, Box, IconButton } from "@mui/material";
import StyledTooltip from "../../../shared/Components/Styled/StyledTooltip";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import useDeviceStore from "../../../store/useDeviceStore";

interface Marker {
  id: string;
  position: {
    lat: number;
    lng: number;
  };
  name: string;
  status: "ONLINE" | "OFFLINE";
}

const Map: React.FC = () => {
  const { allDevices } = useDeviceStore();

  const [markers, setMarkers] = useState<Marker[]>([]);
  const [markersCenter, setMarkersCenter] = useState({ lat: 0, lng: 0 });

  const mapRef = useRef<HTMLDivElement>(null);
  const mapMarkersRef = useRef<L.Map | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const newMarkers: Marker[] = allDevices
      .filter((device) => device.deviceLocation)
      .map((device) => ({
        id: device.deviceInformation.deviceId,
        position: {
          lat: device.deviceLocation!.latitude,
          lng: device.deviceLocation!.longitude
        },
        name: device.deviceInformation.deviceName,
        status: device.pingStatus.status === "ONLINE" ? "ONLINE" : "OFFLINE"
      }));

    setMarkers(newMarkers);

    let validDevices = allDevices.filter(
      (device) =>
        device.deviceLocation?.latitude && device.deviceLocation?.longitude
    );

    if (validDevices.length > 0) {
      let latTotal = validDevices.reduce(
        (acc, device) => acc + device.deviceLocation!.latitude!,
        0
      );
      let lngTotal = validDevices.reduce(
        (acc, device) => acc + device.deviceLocation!.longitude!,
        0
      );
      let latAvg = latTotal / validDevices.length;
      let lngAvg = lngTotal / validDevices.length;
      setMarkersCenter({ lat: latAvg, lng: lngAvg });
    } else {
      setMarkersCenter({ lat: 0, lng: 0 });
    }
  }, [allDevices]);

  useEffect(() => {
    if (mapMarkersRef.current && markers.length > 0) {
      const bounds = L.latLngBounds(markers.map((m) => m.position));
      mapMarkersRef.current.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [markers]);

  const toggleFullscreen = () => {
    if (!mapRef.current) return;

    if (!document.fullscreenElement) {
      mapRef.current.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const commonStyles = {
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: 1
  };

  return (
    <Paper
      elevation={0}
      sx={{
        ...commonStyles,
        pb: 1,
        mb: "0.75rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="body1"
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            flex: 1,
            textAlign: "center"
          }}
        >
          Device Locations
        </Typography>
        <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                bgcolor: "green",
                marginRight: 0.5,
                borderRadius: "50%"
              }}
            />
            <Typography variant="caption" sx={{ marginRight: 2 }}>
              Online
            </Typography>
            <Box
              sx={{
                width: 12,
                height: 12,
                bgcolor: "red",
                marginRight: 0.5,
                borderRadius: "50%"
              }}
            />
            <Typography variant="caption">Offline</Typography>
          </Box>
          <StyledTooltip
            variant="primary-dark"
            title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
            placement="left"
          >
            <IconButton
              onClick={toggleFullscreen}
              sx={{
                backgroundColor: "white",
                "&:hover": { backgroundColor: "#f0f0f0" },
                "& svg": { fontSize: "2rem" }
              }}
            >
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </StyledTooltip>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box
        ref={mapRef}
        sx={{ height: isFullscreen ? "100vh" : "80vh", width: "100%" }}
      >
        <MapContainer
          center={markersCenter}
          zoom={3}
          style={{ height: "100%", width: "100%" }}
          ref={mapMarkersRef}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          {markers.map((marker) => (
            <CircleMarker
              key={marker.id}
              center={marker.position}
              color={marker.status === "ONLINE" ? "green" : "red"}
              radius={3}
              fillOpacity={1}
            >
              <Popup>{`${marker.name} - ${marker.status}`}</Popup>
            </CircleMarker>
          ))}
        </MapContainer>
      </Box>
    </Paper>
  );
};

export default Map;
