import React, { useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  AlertColor,
  Typography,
  useMediaQuery
} from "@mui/material";
import StyledTooltip from "../../../../shared/Components/Styled/StyledTooltip";
import { GridColDef } from "@mui/x-data-grid";
import { SalesData } from "../../../../common/types/DistributorSalesTypes";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";
import StyledIconButton from "../../../../shared/Components/Styled/StyledIconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useAuthStore from "../../../../store/authStore";
import { AddCircle, Close } from "@mui/icons-material";
import theme from "../../../../theme";
import SalesInputForm from "./SalesInputForm";
import { deleteSalesReport } from "../../../../services/distributor.service";
import EditSalesModal from "../Modal/EditSalesModal";
import { AccountsData } from "../../../../common/types/DistributorSalesTypes";

const accountTypeColors: { [key: string]: string } = {
  ISS: "#0057A7",
  Sodexo: "#ef5350",
  FoodBuy: "#6d4c41",
  CushmanWakefield: "#ab47bc",
  Frasers: "#827717",
  WeWork: "#2e7d32",
  JLL: "#00695c",
  McDonalds: "#f06292",
  Accor: "#607d8b",
  NonGlobalAccount: "#424242"
};

type UpdateSalesModalProps = {
  open: boolean;
  onClose: () => void;
  sales: SalesData[];
  month: string;
  year: string;
  accounts: AccountsData[];
  distributorEmail: string;
  distributorFilter: string;
  setSnackbar: (snackbar: {
    open: boolean;
    message: string;
    severity: AlertColor;
  }) => void;
  onDeleteSuccess: () => void;
  onEditSuccess: () => void;
  onAddSales: () => void;
};

const UpdateSalesModal: React.FC<UpdateSalesModalProps> = ({
  open,
  onClose,
  distributorEmail,
  distributorFilter,
  accounts,
  sales,
  month,
  year,
  setSnackbar,
  onDeleteSuccess,
  onEditSuccess,
  onAddSales
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedSales, setSelectedSales] = useState<SalesData | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedDeleteReport, setSelectedDeleteReport] =
    useState<SalesData | null>(null);
  const [addSalesModalOpen, setAddSalesModalOpen] = useState(false);

  const { userRole } = useAuthStore();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDeleteReport = async () => {
    if (selectedDeleteReport?._id) {
      try {
        await deleteSalesReport(selectedDeleteReport._id);
        setSnackbar({
          open: true,
          message: "Report deleted successfully",
          severity: "success"
        });
        onDeleteSuccess();
      } catch (error) {
        console.error("Failed to delete report:", error);
        setSnackbar({
          open: true,
          message: "Failed to delete report",
          severity: "error"
        });
      } finally {
        setOpenDeleteDialog(false);
        setSelectedDeleteReport(null);
      }
    }
  };

  const handleDeleteClick = (report: SalesData) => {
    setSelectedDeleteReport(report);
    setOpenDeleteDialog(true);
  };

  const handleClose = () => {
    onClose();
  };

  const handleEditReportSuccess = async () => {
    onEditSuccess();
    setSnackbar({
      open: true,
      message: "Report edited successfully",
      severity: "success"
    });
  };

  const handleEditReportError = (message: string) => {
    console.error("Failed to edit report:", message);
    setSnackbar({
      open: true,
      message,
      severity: "error"
    });
  };

  const columns: GridColDef[] = [
    // Conditionally add distributorEmail column for Admin users
    ...(userRole === "Admin"
      ? [
          {
            field: "distributorEmail",
            headerName: "Distributor Email",
            flex: 1,
            minWidth: 200,
            valueGetter: (_value: any, row: SalesData) => row.distributorEmail
          }
        ]
      : []),
    {
      field: "accountType",
      headerName: "Account Type",
      flex: 1,
      minWidth: 150,
      valueGetter: (_value, row) => row.account.accountType,
      renderCell: (params) => (
        <Chip
          label={params.value}
          size="small"
          style={{
            backgroundColor: accountTypeColors[params.value],
            color: "white"
          }}
        />
      )
    },
    {
      field: "accountName",
      headerName: "Account Name",
      flex: 1,
      minWidth: 125,
      valueGetter: (_value, row) => row.account.accountName
    },
    {
      field: "accountEmail",
      headerName: "Account Email",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row.account.accountEmail
    },

    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 100,
      valueGetter: (_value, row) => row.product
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1.5,
      minWidth: 100,
      valueFormatter: (value) => new Date(value).toLocaleDateString()
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      minWidth: 125
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1.5,
      minWidth: 125,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              height: "100%",
              width: "100%"
            }}
          >
            <StyledTooltip variant="primary-dark" title="Edit">
              <Box sx={{ lineHeight: 0 }}>
                <StyledIconButton
                  variantType="transparent"
                  onClick={() => {
                    setSelectedSales(row);
                    setOpenEditModal(true);
                  }}
                >
                  <EditIcon />
                </StyledIconButton>
              </Box>
            </StyledTooltip>
            <StyledTooltip variant="primary-dark" title="Delete">
              <Box sx={{ lineHeight: 0 }}>
                <StyledIconButton
                  variantType="transparent"
                  onClick={() => handleDeleteClick(row)}
                >
                  <DeleteIcon />
                </StyledIconButton>
              </Box>
            </StyledTooltip>
          </Box>
        );
      }
    }
  ];

  const filteredSales = useMemo(() => {
    return sales.filter(
      (sale) =>
        sale.product !== "NONE" && // Exclude "NONE" products
        new Date(sale.date || "").getFullYear().toString() === year && // Filter by year
        (distributorFilter === "All" ||
          sale.distributorEmail === distributorFilter) // Filter by distributor
    );
  }, [sales, year, distributorFilter]); // Include year and distributorEmail in dependency array

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f5f5f5"
          }}
        >
          <DialogTitle
            fontSize={isMobile ? 14 : 16}
            sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", pb: 2 }}
          >
            Sales Details for {month}
          </DialogTitle>
          <DialogActions>
            {isMobile ? (
              <StyledIconButton
                variantType="primary"
                color="primary"
                onClick={() => setAddSalesModalOpen(true)}
              >
                <AddCircle />
              </StyledIconButton>
            ) : (
              <StyledButton
                icon={<AddCircle />}
                iconColor={theme.palette.common.white}
                onClick={() => setAddSalesModalOpen(true)}
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.common.white
                }}
              >
                Add Sales
              </StyledButton>
            )}
            <StyledIconButton
              variantType="destructive"
              color="error"
              onClick={handleClose}
            >
              <Close />
            </StyledIconButton>
          </DialogActions>
        </Box>
        <DialogContent>
          <Box sx={{ p: 2, width: "96%" }}>
            <DataGrid
              rows={filteredSales}
              columns={columns}
              getRowId={(row) => row._id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                }
              }}
              pageSizeOptions={[5, 10, 25]}
              disableRowSelectionOnClick
            />
          </Box>
        </DialogContent>
      </Dialog>
      <SalesInputForm
        open={addSalesModalOpen}
        onClose={() => setAddSalesModalOpen(false)}
        onSuccess={() => {
          setSnackbar({
            open: true,
            message: "Sales added successfully",
            severity: "success"
          });
          setAddSalesModalOpen(false);
          onAddSales();
        }}
        onError={() => {
          setAddSalesModalOpen(false);
          setSnackbar({
            open: true,
            message: "Failed to add sales",
            severity: "error"
          });
        }}
        month={month}
        year={year}
        distributorEmail={distributorEmail}
        accounts={accounts}
      />
      {/* Edit Sales Report Modal */}
      {selectedSales && (
        <EditSalesModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onSuccess={handleEditReportSuccess}
          onError={handleEditReportError}
          salesReport={selectedSales}
          accounts={accounts}
        />
      )}
      {/* Delete Sales Report Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <Typography sx={{ px: 3, pt: 1 }}>
          Are you sure you want to delete this report?
        </Typography>
        <DialogActions>
          <StyledButton
            onClick={() => setOpenDeleteDialog(false)}
            variantType="outline-primary"
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={handleDeleteReport} variantType="destructive">
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateSalesModal;
