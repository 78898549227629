import React, { useEffect } from "react";
import { Box, TextField, Typography, Autocomplete } from "@mui/material";
import type { ReconnectData } from "../../../../common/types/InstallDeviceTypes";
import useDeviceStore from "../../../../store/useDeviceStore";
import useAuthStore from "../../../../store/authStore";

interface StepSerialReconnectProps {
  data: ReconnectData;
  updateData: (fields: Partial<ReconnectData>) => void;
}

const StepSerialReconnect: React.FC<StepSerialReconnectProps> = ({
  data,
  updateData
}) => {
  const { userId } = useAuthStore();
  const { fetchDevices, allDevices } = useDeviceStore();

  useEffect(() => {
    fetchDevices();
  }, [userId]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold"
          }}
        >
          Serial Number
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary"
          }}
        >
          Please select the device that you would like to reconnect to Wi-Fi
          network
        </Typography>
      </Box>
      <Autocomplete
        options={allDevices}
        value={
          allDevices.find(
            (device) => device.deviceInformation.deviceId === data.serialNumber
          ) || null
        }
        renderOption={(props, option) => {
          const { key, ...otherProps } = props;
          return (
            <li key={option.deviceInformation.deviceId} {...otherProps}>
              {option.deviceInformation.deviceName} (
              {option.deviceInformation.deviceId})
            </li>
          );
        }}
        getOptionLabel={(option) =>
          `${option.deviceInformation.deviceId} - ${option.deviceInformation.deviceName}`
        }
        onChange={(_event, selectedOption) => {
          updateData({
            serialNumber: selectedOption?.deviceInformation.deviceId || ""
          });
        }}
        renderInput={(params) => (
          <TextField {...params} label="Serial Number" required />
        )}
      />
    </Box>
  );
};

export default StepSerialReconnect;
