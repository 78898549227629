import React from "react";
import { Tab as MuiTab, TabProps, styled } from "@mui/material";

const CustomTab = styled(MuiTab)(({ theme }) => ({
  color: "#212121",
  textTransform: "none",
  padding: "6px 16px",
  minHeight: "32px",
  fontWeight: 400,
  borderRadius: "4px",
  border: "1px solid transparent",
  "&:hover": {
    border: "1px solid #c7c7c7"
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  marginRight: "4px"
}));

const StyledTab: React.FC<TabProps> = (props) => {
  return <CustomTab {...props} />;
};

export default StyledTab;
