import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Dismiss24Regular } from "@fluentui/react-icons";
import StepSerial from "../../Shared/DeviceInstallation/Steps/StepSerial";
import StepName from "../../Shared/DeviceInstallation/Steps/StepName";
import StepCustomer from "../../Shared/DeviceInstallation/Steps/StepCustomer";
import StepAddCustomer from "../../Shared/DeviceInstallation/Steps/StepAddCustomer";
import StepCartridge from "../../Shared/DeviceInstallation/Steps/StepCartridge";
import StepLocation from "../../Shared/DeviceInstallation/Steps/StepLocation";
import StepWifiSetupChoice from "../../Shared/DeviceInstallation/Steps/StepWifiSetupChoice";
import StepWifiSetup from "../../Shared/DeviceInstallation/Steps/StepWifiSetup";
import StepConnecting from "../../Shared/DeviceInstallation/Steps/StepConnecting";
import StepSuccess from "../../Shared/DeviceInstallation/Steps/StepSuccess";
import StepDeviceCreated from "../../Shared/DeviceInstallation/Steps/StepDeviceCreated";
import StepNavigation from "../../Shared/DeviceInstallation/StepNavigation";
import { fetchDeviceDetails } from "../../../services/device.service";
import type {
  InstallationStep,
  InstallationData
} from "../../../common/types/InstallDeviceTypes";
import {
  createICleanDevice,
  sendDeviceInstallationEmail
} from "../../../services/device.service";
import useDeviceStore from "../../../store/useDeviceStore";
import useAuthStore from "../../../store/authStore";

interface InstallationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const INITIAL_DATA: InstallationData = {
  serialNumber: "",
  deviceName: "",
  customerEmail: "",
  cartridgeType: "",
  location: {
    country: "",
    city: "",
    details: ""
  }
};

const InstallationModal: React.FC<InstallationModalProps> = ({
  isOpen,
  onClose
}) => {
  const MAX_RETRY_ATTEMPTS = 3;
  const { user, userRole } = useAuthStore();
  const { allDevices, fetchDevices } = useDeviceStore();
  const [step, setStep] = useState<InstallationStep>("serial");
  const [data, setData] = useState<InstallationData>(INITIAL_DATA);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deviceCreated, setDeviceCreated] = useState(false);
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [pingAttempts, setPingAttempts] = useState(0);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [retryAttempts, setRetryAttempts] = useState(1);
  const [wifiSetupType, setWifiSetupType] = useState<"single" | "multiple">(
    "single"
  );

  const updateData = (fields: Partial<InstallationData>) => {
    setData((prev) => ({ ...prev, ...fields }));
  };

  const goBack = () => {
    switch (step) {
      case "name":
        setStep("serial");
        break;
      case "customer":
        setStep("name");
        break;
      case "add-customer":
        setStep("customer");
        break;
      case "cartridge":
        userRole === "Installer" ? setStep("name") : setStep("customer");
        break;
      case "location":
        setStep("cartridge");
        break;
      case "device-created":
        setStep("location");
        break;
      case "wifi-setup-choice":
        setStep("location");
        break;
      case "wifi-setup":
        setStep("wifi-setup-choice");
        break;
      default:
        break;
    }
  };

  const handleContinue = async () => {
    if (step === "customer") {
      setStep("cartridge");
    } else if (step === "location") {
      setStep("device-created");
      await handleCreateDevice();
    } else if (step === "wifi-setup") {
      setStep("connecting");
    } else if (step === "success") {
      onClose();
    } else {
      nextStep();
    }
  };

  const handleCreateDevice = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const devicePayload = {
        deviceInformation: {
          deviceId: data.serialNumber,
          serial: data.serialNumber,
          deviceName: data.deviceName,
          customerEmail: data.customerEmail,
          installerEmail: user?.email.toLowerCase(),
          installationCartridgeType: data.cartridgeType
        },
        deviceLocation: {
          latitude: 0,
          longitude: 0,
          country: data.location.country,
          state: "",
          city: data.location.city,
          address: "",
          postalCode: "",
          room: "",
          door: "",
          block: "",
          building: "",
          additionalDetails: data.location.details
        }
      };

      const response = await createICleanDevice(devicePayload);
      allDevices.unshift(response.device);
      setDeviceId(response.device.deviceInformation.deviceId);
      setDeviceCreated(true);
      return response.device.deviceInformation.deviceId;
    } catch (err: any) {
      console.error("Failed to create device:", err);
      setError(err || "Failed to create device");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const startConnectionCheck = () => {
    setPingAttempts(0);
    setError(null);
    checkDeviceConnection();
  };

  const handleRetryConnection = () => {
    if (retryAttempts >= MAX_RETRY_ATTEMPTS) {
      setError(
        "Maximum retry attempts reached. Please wait 30 minutes and try again later."
      );
      return;
    }
    setRetryAttempts((prev) => prev + 1);
    startConnectionCheck();
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (step === "connecting" && deviceId) {
      // Set interval to check every 5 seconds, but stop after 12 attempts
      intervalId = setInterval(() => {
        if (pingAttempts >= 12) {
          clearInterval(intervalId);
          setError(
            "Device connection timeout after 12 attempts. Please ensure the device is properly connected to WiFi and try again."
          );
          return;
        }
        checkDeviceConnection();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [step, deviceId, pingAttempts]);

  const checkDeviceConnection = async () => {
    if (!deviceId) {
      console.log("No deviceId available for connection check");
      return;
    }

    setIsConnecting(true);
    setError(null);

    try {
      const deviceDetails = await fetchDeviceDetails(deviceId);
      const isOnline = deviceDetails.pingStatus.status === "ONLINE";

      if (isOnline) {
        setIsConnecting(false);
        fetchDevices();

        if (!isEmailSent) {
          try {
            await sendDeviceInstallationEmail(
              data.customerEmail,
              data.deviceName
            );
            setIsEmailSent(true);
          } catch (emailErr) {
            console.error("Failed to send email:", emailErr);
            setIsEmailSent(true);
          }
        }
        setStep("success");
      } else {
        setPingAttempts((prev) => {
          const newAttempts = prev + 1;
          if (newAttempts >= 12) {
            setError("Unable to connect to device. Please try again.");
            setIsConnecting(false);
          }
          return newAttempts;
        });
      }
    } catch (err: any) {
      console.error("Connection check error:", err);
      setError(err.message || "Failed to connect to device");
    }
  };

  const nextStep = () => {
    switch (step) {
      case "serial":
        setStep("name");
        break;
      case "name":
        userRole === "Installer" ? setStep("cartridge") : setStep("customer");
        break;
      case "customer":
        setStep("cartridge");
        break;
      case "cartridge":
        setStep("location");
        break;
      case "device-created":
        setStep("wifi-setup-choice");
        break;
      default:
        break;
    }
  };

  const canContinue = () => {
    switch (step) {
      case "serial":
        return data.serialNumber !== "";
      case "name":
        return data.deviceName !== "";
      case "customer":
        return data.customerEmail !== "";
      case "cartridge":
        return data.cartridgeType !== "";
      case "location":
        return data.location.country !== "" && data.location.city !== "";
      case "device-created":
      case "wifi-setup-choice":
      case "wifi-setup":
      case "success":
        return true;
      default:
        return false;
    }
  };

  const handleSetupTypeSelect = (type: "single" | "multiple") => {
    setWifiSetupType(type);
    setStep("wifi-setup");
  };

  const renderStep = () => {
    switch (step) {
      case "serial":
        return <StepSerial data={data} updateData={updateData} />;
      case "name":
        return <StepName data={data} updateData={updateData} />;
      case "customer":
        return (
          <StepCustomer
            data={data}
            updateData={updateData}
            onAddNewCustomer={() => setStep("add-customer")}
          />
        );
      case "add-customer":
        return (
          <StepAddCustomer
            data={data}
            updateData={updateData}
            onSuccess={() => setStep("cartridge")}
          />
        );
      case "cartridge":
        return <StepCartridge data={data} updateData={updateData} />;
      case "location":
        return <StepLocation data={data} updateData={updateData} />;
      case "device-created":
        return (
          <StepDeviceCreated
            isLoading={isLoading}
            error={error}
            text="Click continue to proceed with WiFi setup"
          />
        );
      case "wifi-setup-choice":
        return (
          <StepWifiSetupChoice onSelectSetupType={handleSetupTypeSelect} />
        );
      case "wifi-setup":
        return (
          <StepWifiSetup
            serialNumber={data.serialNumber}
            deviceSetup={wifiSetupType}
          />
        );
      case "connecting":
        return (
          <StepConnecting
            isConnecting={isConnecting}
            error={error}
            pingAttempts={pingAttempts}
            onRetry={handleRetryConnection}
            retryAttempts={retryAttempts}
            maxRetryAttempts={MAX_RETRY_ATTEMPTS}
          />
        );
      case "success":
        return <StepSuccess isEmailSent={isEmailSent} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.85)"
          }
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
        >
          <Dismiss24Regular />
        </IconButton>
      </DialogTitle>
      <DialogContent>{renderStep()}</DialogContent>
      <StepNavigation
        step={step}
        isLoading={isLoading || isConnecting}
        onBack={goBack}
        onContinue={handleContinue}
        canContinue={canContinue()}
        isDeviceCreated={deviceCreated}
        isEmailSent={isEmailSent}
        customerEmail={data.customerEmail}
        error={error}
      />
    </Dialog>
  );
};

export default InstallationModal;
